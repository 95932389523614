import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/loader/Loader";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import { promoSchema } from "../../app/schema";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../redux/counterSlice";
import { CurrencyRupee, Percent } from "@mui/icons-material";
import { FormInputText } from "../../components/form-components/FormInputText";

function Promo() {
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.counter);

  const methods = useForm({
    resolver: yupResolver(promoSchema),
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const loadInitialData = async () => {
    try {
      setLoader(true);
      const response = await axios.get("/promo");
      setFormData(response?.data?.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "promo_code",
      label: "Promo Code",
    },

    {
      name: "type",
      label: "Type",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return formData?.[dataIndex]?.type === "PERCENTAGE" ? (
            <Percent sx={{ fontSize: 20 }} />
          ) : (
            <CurrencyRupee sx={{ fontSize: 20 }} />
          );
        },
      },
    },

    {
      name: "discount",
      label: "Amount/Percent",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <p>
              {formData?.[dataIndex]?.type === "AMOUNT" && "₹ "}
              {formData[dataIndex]?.discount}
              {formData?.[dataIndex]?.type === "PERCENTAGE" && " %"}
            </p>
          );
        },
      },
    },
    {
      name: "min_value",
      label: "Min Value",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>₹ {formData[dataIndex]?.min_value}</p>;
        },
      },
    },

    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                dispatch(
                  showConfirmModal({
                    msg: status === 1 ? "Deactivate" : "Activate",
                    action: () =>
                      toggleStatus(
                        formData[dataIndex]?.id,
                        formData[dataIndex]?.active_status
                      ),
                  })
                );
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Edit",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  dispatch(
                    showConfirmModal({
                      msg: "Delete",
                      action: () => deleteClickHandler(formData[dataIndex]?.id),
                    })
                  );
                }}
              >
                <DeleteOutlineOutlinedIcon
                  color="error"
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "red" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("promoSwitch/" + id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      data["promo_code"] = data?.promo_code?.toUpperCase();
      if (selectedRow?.id) {
        response = await axios.put("/promo/" + selectedRow?.id, data);
      } else {
        response = await axios.post("/promo", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow(() => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
        setOpen(false);
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
      setOpen(false);
    } finally {
      setLoader(false);
    }
  };
  const deleteClickHandler = async (id) => {
    setLoader(true);
    try {
      let response;
      if (id) {
        response = await axios.delete("/promo/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow(() => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
    }
  };
  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Promo" : "New Promo"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={6}>
              <FormInputText
                name="promo_code"
                control={control}
                label="Promo Code"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.promo_code}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="min_value"
                control={control}
                label="Minimum Value"
                errors={errors}
                mandatory="true"
                type="number"
                defaultValue={selectedRow?.min_value}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputDropdown
                name="type"
                control={control}
                label="Type"
                type="single"
                options={[
                  {
                    id: "AMOUNT",
                    name: "Amount (₹)",
                  },
                  {
                    id: "PERCENTAGE",
                    name: "Percentage (%)",
                  },
                ]}
                errors={errors}
                defaultValue={selectedRow?.type}
                mandatory="true"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="discount"
                control={control}
                label="Amount / Percent"
                errors={errors}
                mandatory="true"
                type="number"
                defaultValue={selectedRow?.discount}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="terms_conditions"
                control={control}
                defaultValue={selectedRow?.terms_conditions || ""}
                render={({ field: { onChange, value } }) => (
                  <textarea
                    className="custom-textarea"
                    aria-label="rejection comment"
                    placeholder="Terms & Conditions"
                    value={value}
                    style={{ width: "100%", height: "100px" }}
                    onChange={onChange}
                    rowsMin={3}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    viewColumns: true,
    print: false,
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Promo</h4>
          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                icon={<AddIcon />}
              >
                New Promo
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
    </>
  );
}

export default Promo;
