import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { blue, grey } from "@mui/material/colors";

export const FormInputTextArea = ({
  name,
  control,
  label,
  type,
  minRows,
  placeholder,
  defaultValue,
  maxLength,
  className,
  disabled,
  maxRows,
}) => {
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[400] : grey[400]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Textarea
            sx={{ marginTop: "16px" }}
            fullWidth
            maxRows={maxRows}
            aria-label="maximum height"
            placeholder={placeholder}
            minRows={minRows}
            onChange={onChange}
            defaultValue={defaultValue || value}
            error={error?.message ? true : false}
          />
          // <TextField
          //   fullWidth
          //   id="outlined-basic"
          //   label={label}
          //   // size="small"
          //   // helperText={errors?.[name]?.message}
          //   type={type || "text"}
          //   placeholder={placeholder}
          //   className={className}
          //   variant="outlined"
          //   onChange={onChange}
          //   defaultValue={defaultValue || value}
          //   maxLength={maxLength}
          //   error={error?.message ? true : false}
          //   margin="normal"
          //   disabled={disabled || false}
          // />
        );
      }}
    />
  );
};
