import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormInputDate = ({
  name,
  control,
  label,
  errors,
  type,
  defaultValue,
  mandatory,
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      control={control}
      render={({
        field: { ref, onChange, ...rest },
        formState,
        fieldState,
      }) => {
        return (
          <div className="mt-3">
            <DatePicker
              format="dd-MM-yyyy"
              label={label}
              // onChange={(date) =>
              //   onChange(format(new Date(date), "yyyy-MM-dd"))
              // }
              onChange={(date) => onChange(date)}
              {...rest}
            />
          </div>
        );
      }}
    />
  );
};
