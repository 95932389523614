import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import { PhotoProvider, PhotoView } from "react-photo-view";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate, useParams } from "react-router-dom";
import DriverDetailsTable from "../../order/orderList/DriverDetailsTable";
import { useDispatch } from "react-redux";
import OrderListTable from "../../order/orderList/OrderListTable";
import OrderSummary from "../../order/orderList/OrderSummary";

function ViewShopOrder() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);

  const [orderList, setOrderList] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [dispatchOrder, setDispatchOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderId, setOrderId] = useState();

  const loadInitialData = () => {
    const url = "/getShopOrderByShopOrderId/" + id;
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setDispatchOrder(response?.data?.data);
        setOrderDetails(response?.data?.data?.[0]?.order);
        setOrderList(response?.data?.data?.[0]?.order?.ordered_products);
        setAddonList(response?.data?.data?.[0]?.order?.ordered_addons);
        setOrderId(response?.data?.data?.[0]?.order_id);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const acceptOrders = async () => {
    try {
      const params = {
        dispatch_order_id: id,
        order_id: orderId,
      };
      const response = await axios.post("/acceptShopOrders", params);

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data)?.map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    }
  };
  const pickedUp = async () => {
    try {
      const params = {
        shop_order_id: id,
        order_id: orderId,
      };
      const response = await axios.post("/deliveredFromShop", params);

      if (!response?.data?.error) {
        dispatch(hideConfirmModal({ msg: "" }));
        navigate("/shop-panel");

        toast.success(response?.data?.message);
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data)?.map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <ArrowBackIcon
                className="text-danger"
                sx={{ marginRight: "1rem", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h4 className="text-dark">Shop Order Details</h4>
            </div>
            {dispatchOrder?.[0]?.shop_acceptance != 1 && !loader && (
              <Button
                variant="contained"
                color="info"
                onClick={() => acceptOrders()}
              >
                Accept Order
              </Button>
            )}
            {dispatchOrder[0]?.shop_acceptance == 1 &&
              !loader &&
              !dispatchOrder[0]?.driver_id &&
              orderDetails?.delivery_mode == "Pick Up" && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={() =>
                    dispatch(
                      showConfirmModal({
                        msg: "Deliver this order",
                        action: () => pickedUp(),
                      })
                    )
                  }
                >
                  Customer Picked Up
                </Button>
              )}
          </div>

          <Grid item xs={12} sx={{ mb: 1 }}>
            {/* <Grid container spacing={2}> */}
            {/* {orderList?.map((order, index) => (
                <Grid item key={index} xs={12} md={6} lg={3} xl={3}>
                  <Card>
                    <CardContent>
                      <div className="py-6 px-6 align-items-center">
                        <div className="row mb-6 justify-content-between">
                          <div className="col-4">
                            {order?.product_details[0]?.images.map(
                              (image, k) => (
                                <div key={k} className="w-icon viewIm">
                                  <PhotoProvider>
                                    <PhotoView
                                      key={index}
                                      src={image?.product_image}
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          borderRadius: 8,
                                          cursor: "pointer",
                                        }}
                                        src={image?.product_image}
                                        alt="user avatar"
                                        className="customer-img"
                                      />
                                    </PhotoView>
                                  </PhotoProvider>
                                </div>
                              )
                            )}
                          </div>
                          <div className="col-8">
                            <div className="media-body ml-3 border-left-xs border-light-3">
                              <div className="d-flex flex-column order-details-box">
                                <Typography
                                  variant="h6"
                                  className="mb-0 mobile-view-prod-name"
                                >
                                  {order.product_details[0]?.product_name}
                                </Typography>

                                <Typography variant="subtitle1">
                                  Weight:{" "}
                                  {order.variation.weight[0].weight_name}{" "}
                                  {order.variation.weight[0].unit.unit_name}
                                </Typography>
                                <Typography variant="subtitle1">
                                  Quantity: {order?.product_quantity}
                                </Typography>
                                {order.egg_or_eggless === 0 && (
                                  <Typography variant="subtitle1">
                                    Cake Type:{" "}
                                    <span className="text-danger">Egg</span>
                                  </Typography>
                                )}
                                {order.egg_or_eggless === 1 && (
                                  <Typography variant="subtitle1">
                                    Cake Type:{" "}
                                    <span className="text-success">
                                      Eggless
                                    </span>
                                  </Typography>
                                )}
                                {order.flavour.flavour_name !== "Default" && (
                                  <Typography variant="subtitle1">
                                    Flavour: {order.flavour.flavour_name}
                                  </Typography>
                                )}
                                {order.message_on_cake !== "" && (
                                  <Typography variant="subtitle1">
                                    Message On Cake: {order.message_on_cake}
                                  </Typography>
                                )}
                                <Typography variant="subtitle1">
                                  Slot:{" "}
                                  {onlyStartTime(dispatchOrder[0].order.slot)}
                                </Typography>
                                <Typography variant="subtitle1">
                                  Delivery Date:{" "}
                                  {dispatchOrder[0].order.expected_delivery}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))} */}

            {/* </Grid> */}
            <Grid item xs={12}>
              <OrderListTable
                productList={orderDetails?.ordered_products}
                addonList={orderDetails?.ordered_addons}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {dispatchOrder?.[0]?.driver && (
              <DriverDetailsTable getDriverOrderId={dispatchOrder} />
            )}
          </Grid>
          <Grid item xs={12}>
            <h5 className="text-primary mt-3">Order Summary</h5>
            <OrderSummary orderDetails={orderDetails} />
          </Grid>
        </>
      )}
    </>
  );
}

export default ViewShopOrder;
