import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { Controller } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { defaultDropDown, preparationHours } from "../../../app/utlils";
import { styled } from "@mui/material/styles";

const initialRows = [];
const MUIDataTableWithoutElevation = styled(MUIDataTable)({
  boxShadow: "none", // Remove the box shadow
});

export default function ProductRegionBasedAttributes({
  selectedRegions,
  control,
  getSKU,
  weightList,
  getValues,
  setValue,
}) {
  const columns = [
    {
      name: "name",
      label: "Region",
      options: {
        setCellProps: () => ({
          style: { width: "30px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              name={`product_region_attributes[${dataIndex}].region`}
              control={control}
              label="Region"
              disabled
              mandatory="true"
              disable={true}
              defaultValue={selectedRegions[dataIndex].label}
            />
          );
        },
      },
    },

    {
      name: "new_product",
      label: "New Product",
      options: {
        setCellProps: () => ({
          style: { width: "150px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputDropdown
              name="new_product"
              control={control}
              label="New Product"
              type="single"
              options={defaultDropDown}
              // defaultValue={product_region_attributes[dataIndex].variation_id}
              mandatory="true"
            />
          );
        },
      },
    },
    {
      name: "best_selling",
      label: "Best Seller",

      options: {
        setCellProps: () => ({
          style: { width: "150px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputDropdown
              name="best_selling"
              control={control}
              label="Best Seller"
              type="single"
              options={defaultDropDown}
              // defaultValue={product_region_attributes[dataIndex]?.best_selling}
              mandatory="true"
            />
          );
        },
      },
    },

    // {
    //   name: "preparation",
    //   label: "Preparation",

    //   options: {
    //     setCellProps: () => ({
    //       style: { width: "250px" },
    //     }),
    //     customBodyRenderLite: (dataIndex) => {
    //       return (
    //         <FormInputDropdown
    //           name={`preparation`}
    //           control={control}
    //           label="Preparation"
    //           type="single"
    //           options={preparationHours?.map((item) => ({
    //             id: item?.id,
    //             name: item?.name,
    //           }))}
    //           // defaultValue={
    //           //   product_region_attributes[dataIndex].preparation || 0
    //           // }
    //           mandatory="true"
    //         />
    //       );
    //     },
    //   },
    // },
  ];
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
    pagination: false,
    search: false,
  };
  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <MUIDataTableWithoutElevation
        // className={classes.root}
        // className="bg-light"
        data={selectedRegions || []}
        columns={columns}
        options={options}
        columnVisibilityModel={{
          variation_id: false,
        }}
        title={
          <FormLabel component="legend" sx={{ m: 0 }}>
            Region Based Attributes
          </FormLabel>
        }
      />
    </Box>
  );
}
