import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function DriverOrderItem({ driver }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ backgroundColor: "whitesmoke", m: 3 }}>
      <CardContent>
        <div
          className="media align-items-center"
          style={{ position: "relative" }}
        >
          {driver?.order?.ordered_products?.length > 1 && (
            <Chip
              label={`Count: ${driver?.order?.ordered_products?.length}`}
              color="success"
              style={{ position: "absolute", top: 8, right: 8 }}
            />
          )}
          <div className="w-icon imgWidth position-relative">
            <img
              style={{ width: "100%", borderRadius: 8 }}
              src={
                driver?.order?.ordered_products?.[0]?.product_details?.[0]
                  ?.images?.[0].app_product_image
              }
              alt="user avatar"
              className="customer-img"
            />
            <Typography variant="p" color="primary" className="order-label">
              Order ID: #{driver?.order_id}
            </Typography>
          </div>
          <div className="media-body ml-3 border-left-xs border-light-3">
            <Typography variant="h6" color="error" className="mb-0 ">
              {
                driver?.order?.ordered_products?.[0]?.product_details?.[0]
                  ?.product_name
              }
            </Typography>

            <Typography variant="body2">
              Weight:
              {
                driver?.order?.ordered_products?.[0]?.variation?.weight?.[0]
                  ?.weight_name
              }
              {
                driver?.order?.ordered_products?.[0]?.variation?.weight?.[0]
                  ?.unit?.unit_name
              }
            </Typography>
            {driver?.order?.ordered_products?.[0]?.flavour?.flavour_name !==
              "Default" && (
              <Typography variant="body2" body2>
                Flavour:
                {driver?.order?.ordered_products?.[0]?.flavour?.flavour_name}
              </Typography>
            )}
            <hr
              style={{
                marginTop: "1em",
                marginBottom: "1em",
              }}
            />
          </div>
        </div>
        <Grid container justifyContent="space-around" alignItems="center">
          <Button
            variant="contained"
            color="info"
            onClick={() =>
              navigate(`/view-driver-order/${driver?.driver_id}/${driver?.order_id}`)
            }
            className="cusBtn"
          >
            View Order
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DriverOrderItem;
