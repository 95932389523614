import React, { useEffect, useState } from "react";

import {
  Alert,
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addonSchema } from "../../../app/schema";
import {
  handlePageSettings,
  hideConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import OrderSplitButton from "./OrderSplitButton";
import { formatDate, parseStatusColor } from "../../../app/utlils";
import ThreeDotMenu from "./ThreeDotMenu";

function OrderList({ type }) {
  const dispatch = useDispatch();
  const {
    pageSettings: {
      order: { pageSize, pageNo },
    },
  } = useSelector((state) => state.counter);
  const methods = useForm({
    resolver: yupResolver(addonSchema),
  });
  const { reset } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    Submitted: 0,
    Confirmed: 0,
    Dispatched: 0,
    ReadyToDelivered: 0,
    Delivered: 0,
  });

  const loadInitialData = () => {
    let url = "/listAllOrders";
    if (type === "cancelled") {
      url = "/listCancelledOrders";
    } else if (type === "failed") {
      url = "/listAllOrdersBackup";
    } else if (type === "delivered") {
      url = "/listAllDeliveredOrders";
    }
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setFormData(response?.data?.data);
        const Submitted = response.data?.data?.filter(
          (item) => item?.order_status === "Submitted"
        )?.length;
        const Confirmed = response.data?.data?.filter(
          (item) => item?.order_status === "Confirmed"
        )?.length;
        const Dispatched = response.data?.data?.filter(
          (item) => item?.order_status === "Dispatched"
        )?.length;
        const ReadyToDelivered = response.data?.data?.filter(
          (item) => item?.order_status === "ReadyToDelivered"
        )?.length;
        const Delivered = response.data?.data?.filter(
          (item) => item?.order_status === "Delivered"
        )?.length;
        setStatusCounts({
          Submitted,
          Confirmed,
          Dispatched,
          ReadyToDelivered,
          Delivered,
        });
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  useEffect(() => {
    loadInitialData();
  }, [type]);

  const columns = [
    {
      name: "order_id",
      label: "Order ID",
      options: {
        filter: false,
      },
    },
    {
      name: "delivery_mode",
      label: "Delivery Type",
    },

    {
      name: "region.city.name",
      label: "Location",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const district =
            formData[dataIndex]?.ordered_address?.address_city_district;
          const shopName = formData[dataIndex]?.shops?.shop_name;
          const mode = formData[dataIndex]?.delivery_mode;
          return <p>{mode === "Door Step Delivery" ? district : shopName}</p>;
        },
        filter: true,
        // customFilterListOptions: { render: (v) => `Name: ${v}` },
        filterOptions: {
          names: [
            ...new Set(formData?.map((item) => item?.region?.city?.name)),
          ],
          logic(location, filters) {
            if (filters.length) return !filters.includes(location);
            return false;
          },
        },
      },
    },

    {
      name: "order_submitted_at",
      label: "Order Placed At",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const order_submitted_at = formData[dataIndex]?.order_submitted_at;
          const parsedDate = formatDate(new Date(order_submitted_at));
          return parsedDate;
        },
      },
    },
    {
      name: "expected_delivery",
      label: "Expected Delivery",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const expected_delivery = formData[dataIndex]?.expected_delivery;
          const slot_timing = formData[dataIndex]?.slot_timing;

          const date = new Date(expected_delivery);
          const formattedDate = `${date
            .getDate()
            .toString()
            .padStart(2, "0")}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getFullYear()}`;
          return `${formattedDate} (${slot_timing})`;
        },
      },
    },
    {
      name: "order_overall_totall",
      label: "Amount",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const order_overall_totall =
            formData[dataIndex]?.order_overall_totall;
          return `₹${order_overall_totall}`;
        },
      },
    },
    {
      name: "order_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const order_status = formData[dataIndex]?.order_status;
          const { bgColor, textColor } = parseStatusColor(order_status);
          return (
            <Chip
              label={order_status}
              size="small"
              sx={{ background: bgColor, color: textColor }}
            />
          );
        },
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRenderLite: (dataIndex) => {
          // return <OrderSplitButton id={formData[dataIndex]?.order_id} />;
          return <ThreeDotMenu id={formData[dataIndex]?.order_id} />;
        },
        filter: false,
      },
    },
  ];

  const options = {
    enableNestedDataAccess: ".",
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
    rowsPerPage: pageSize,
    page: pageNo,
    filter: true,
    onChangeRowsPerPage: (numberOfRows) =>
      dispatch(
        handlePageSettings({
          screen: "order",
          pageSize: numberOfRows,
          pageNo: pageNo,
        })
      ),
    onChangePage: (currentPage) =>
      dispatch(
        handlePageSettings({
          screen: "order",
          pageSize: pageSize,
          pageNo: currentPage,
        })
      ),
  };
  const StatusButtons = () => {
    if (type == "cancelled" || type == "failed" || type == "delivered") {
      return;
    }
    const statusList = [
      "Submitted",
      "Confirmed",
      "Dispatched",
      "ReadyToDelivered",
      "Delivered",
    ];
    return (
      <>
        <div className="status-buttons-container">
          <h6 className="mb-0">Status Count:</h6>
          {statusList?.map((item) => {
            const { bgColor, textColor } = parseStatusColor(item);
            return (
              <Badge
                showZero
                badgeContent={statusCounts[item]}
                sx={{
                  "& .MuiBadge-badge": {
                    color: textColor,
                    backgroundColor: bgColor,
                  },
                }}
              />
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Order List</h4>

          {formData?.length > 0 ? (
            <MUIDataTable
              data={formData}
              columns={columns}
              options={options}
              title={<StatusButtons />}
            />
          ) : (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="warning">
                <h6>No Data Found..</h6>
              </Alert>
            </Stack>
          )}
        </>
      )}

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderList;
