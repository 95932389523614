import React from "react";
import {
  Paper,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Grid,
  Button,
  Chip,
  Link,
} from "@mui/material";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { MyLocationOutlined } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

function OrderSummary({ orderDetails }) {
  const openCommentPopUp = (holdReason) => {
    // Implement your logic for opening the comment popup
    console.log("Hold Reason:", holdReason);
  };
  const DoorStepShippingAddressDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Shipping Address</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Contact Name:</TableCell>
              <TableCell>{orderDetails?.contact_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact No:</TableCell>
              <TableCell>{orderDetails?.contact_mobile}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>
                {orderDetails?.ordered_address?.full_address}
                <br />
                {orderDetails?.ordered_address?.address_city_district} -{" "}
                {orderDetails?.ordered_address?.address_pincode}
                <br />
                {orderDetails?.ordered_address?.address_state}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Map:</TableCell>
              <TableCell>
                <MyLocationOutlined
                  sx={{ color: "red", fontSize: "18px", mr: 1 }}
                />
                <Link
                  target="_blank"
                  rel="noopener"
                  color="primary"
                  href={`https://maps.google.com/?q=${orderDetails?.ordered_address?.latitude},${orderDetails?.ordered_address?.longitude}`}
                >
                  Locate on map
                </Link>
              </TableCell>
            </TableRow>
            {orderDetails?.hold === 1 && (
              <>
                <TableRow>
                  <TableCell>Hold Reason:</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openCommentPopUp(orderDetails?.hold_reason)
                      }
                      style={{ height: "20px" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hold By:</TableCell>
                  <TableCell>{orderDetails?.hold_by}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const PickUpShippingDetails = () => {
    return (
      <TableContainer>
        <div className="card bg-light p-3">
          <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
            <TableHead>
              <TableRow className="thead-light">
                <TableCell colSpan={2}>
                  <h5>Pick Up Location</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="order-summary">
              <TableRow>
                <TableCell>Contact Name:</TableCell>
                <TableCell>{orderDetails?.contact_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact No:</TableCell>
                <TableCell>{orderDetails?.contact_mobile}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address:</TableCell>
                <TableCell>
                  Blaack Forest <br />
                  {orderDetails?.shops?.shop_name}
                  <br />
                  {orderDetails?.shops?.address}
                  <br />
                  Pincode: {orderDetails?.shops?.pincode}
                  <br />
                  {orderDetails?.region?.city?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Store Contact No:</TableCell>
                <TableCell>{orderDetails?.shops.mobile_no}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    );
  };
  const PaymentDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Payment Details</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Payment Mode:</TableCell>
              <TableCell>{orderDetails?.payment_mode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Razor Pay ID:</TableCell>
              <TableCell>{orderDetails?.razorpay_payment_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payment:</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={orderDetails?.paid == 1 ? "DONE" : "PENDING"}
                  color={orderDetails?.paid == 1 ? "success" : "error"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paid At:</TableCell>
              <TableCell>{orderDetails?.paid_at}</TableCell>
            </TableRow>
            {orderDetails?.business_name && (
              <TableRow>
                <TableCell>Business Name:</TableCell>
                <TableCell>{orderDetails?.business_name}</TableCell>
              </TableRow>
            )}
            {orderDetails?.gstin && (
              <TableRow>
                <TableCell>GSTIN :</TableCell>
                <TableCell>{orderDetails?.gstin}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const CustomerDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead">
              <TableCell colSpan={2}>
                <h5>Customer Details</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Name:</TableCell>
              <TableCell>
                <b>{orderDetails?.customer_details?.customer_name}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact No:</TableCell>
              <TableCell>
                <b>{orderDetails?.customer_details?.customer_mobile}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email:</TableCell>
              <TableCell>{orderDetails?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Contact No:</TableCell>
              <TableCell>{orderDetails?.contact_mobile}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };
  const Summary = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-light table-hover">
          <TableHead>
            <TableRow className="thead-light">
              <TableCell colSpan={2}>
                <h5>Summary</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Order ID:</TableCell>
              <TableCell>{orderDetails?.order_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Date:</TableCell>
              <TableCell>
                {new Date(orderDetails?.order_submitted_at).toLocaleString()}
              </TableCell>
            </TableRow>
            {orderDetails?.invoice_no && (
              <TableRow>
                <TableCell>Invoice No:</TableCell>
                <TableCell>{orderDetails?.invoice_no}</TableCell>
              </TableRow>
            )}
            {orderDetails?.paid_at && (
              <TableRow>
                <TableCell>Paid at:</TableCell>
                <TableCell>
                  {new Date(orderDetails?.paid_at).toLocaleString()}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Expected Delivery:</TableCell>
              <TableCell>{orderDetails?.expected_delivery}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Slot:</TableCell>
              <TableCell>{orderDetails?.slot_timing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Total:</TableCell>
              <TableCell>
                &#8377; {orderDetails?.order_overall_totall}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Order Status:</TableCell>
              <TableCell>{orderDetails?.order_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delivery Type:</TableCell>
              <TableCell style={{ fontWeight: "bold", color: "blue" }}>
                {orderDetails?.delivery_mode}
              </TableCell>
            </TableRow>

            {orderDetails?.hold === 1 && (
              <>
                <TableRow>
                  <TableCell>Hold Reason:</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        openCommentPopUp(orderDetails?.hold_reason)
                      }
                      style={{ height: "20px" }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Hold By:</TableCell>
                  <TableCell>{orderDetails?.hold_by}</TableCell>
                </TableRow>
              </>
            )}
            {orderDetails?.checkout_tracking_id && (
              <TableRow>
                <TableCell>Checkout Tracking ID:</TableCell>
                <TableCell>{orderDetails?.checkout_tracking_id}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  };
  const BillingDetails = () => {
    return (
      <div className="card bg-light p-3">
        <Table className="w-100 table table-sm table-borderless text-center table-hover table-light">
          <TableHead>
            <TableRow className="thead">
              <TableCell colSpan={2}>
                <h5>Bill Summmary</h5>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="order-summary">
            <TableRow>
              <TableCell>Billing Name:</TableCell>
              <TableCell className="align-right">
                {orderDetails?.billing_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Billing Mobile No.:</TableCell>
              <TableCell className="align-right">
                {orderDetails?.billing_mobile_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cake Total:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.cake_subtotal || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            {orderDetails?.promo_code_value > 0 && (
              <TableRow>
                <TableCell>
                  Discount: (
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {orderDetails?.promo_code}
                  </span>
                  ) &nbsp;
                  {orderDetails?.promo_type == "PERCENTAGE" && (
                    <span style={{ color: "red" }}>
                      {orderDetails?.promo_code_percentage}%
                    </span>
                  )}
                </TableCell>
                <TableCell className="align-right">
                  <b>- ₹ {(+orderDetails?.promo_code_value || 0).toFixed(2)}</b>
                </TableCell>
              </TableRow>
            )}
            {orderDetails?.order_price_details?.addon_total_amount > 0 && (
              <TableRow>
                <TableCell>Addon Total:</TableCell>
                <TableCell className="align-right">
                  ₹{" "}
                  <b>
                    {(
                      +orderDetails?.order_price_details?.addon_total_amount ||
                      0
                    ).toFixed(2)}
                  </b>
                </TableCell>
              </TableRow>
            )}
            {orderDetails?.deliver_fee_type == 0 &&
              orderDetails?.deliver_fee > 0 && (
                <TableRow>
                  <TableCell>Delivery Fee:</TableCell>
                  <TableCell className="align-right">
                    ₹ <b>{(+orderDetails?.deliver_fee || 0).toFixed(2)}</b>
                  </TableCell>
                </TableRow>
              )}
            {orderDetails?.deliver_fee_type == 1 &&
              orderDetails?.actual_delivery_fee > 0 && (
                <TableRow>
                  <TableCell>
                    Delivery Fee: &nbsp;<span className="free-tag">FREE</span>
                  </TableCell>
                  <TableCell
                    className="align-right"
                    style={{ textDecoration: "line-through" }}
                  >
                    ₹{" "}
                    <b>
                      {(+orderDetails?.actual_delivery_fee || 0).toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
              )}
            <TableRow style={{ borderTop: "1px solid gray" }}>
              <TableCell>Taxable:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.taxable_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GST:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.gst_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
            <TableRow style={{ borderTop: "1px solid gray" }}>
              <TableCell>Total:</TableCell>
              <TableCell className="align-right">
                ₹{" "}
                <b>
                  {(
                    +orderDetails?.order_price_details?.total_amount || 0
                  ).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <Grid container columnSpacing={{ xs: 5 }} id="order-summary">
        <Grid
          item
          xs={4}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <Summary />
          <PaymentDetails />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <TableContainer>
            {orderDetails?.delivery_mode == "Door Step Delivery" ? (
              <DoorStepShippingAddressDetails />
            ) : (
              <PickUpShippingDetails />
            )}
          </TableContainer>
          <CustomerDetails />
        </Grid>
        <Grid item xs={4}>
          <BillingDetails />
        </Grid>
      </Grid>
    </>
  );
}

export default OrderSummary;
