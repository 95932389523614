import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { hideConfirmModal } from "../../redux/counterSlice";

export default function ConfirmDialog() {
  const dispatch = useDispatch();
  const { message, flag, action } = useSelector(
    (state) => state.counter.confirmModal
  );

  const confirmHandler = () => {
    action();
  };

  const handleClose = () => {
    dispatch(hideConfirmModal({ msg: "" }));
  };

  return (
    <div>
      <Dialog open={flag} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {message} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            id="confirmButton"
            variant="contained"
            onClick={confirmHandler}
            autoFocus={true}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
