import React, { memo, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { LinearProgress, Paper, Switch } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { showModal } from "../../app/store/slice/counterSlice";
import { useDispatch } from "react-redux";

const FlavourSectionBackgroundUpload = ({
  name,
  control,
  label,
  setValue,
  status,
  defaultValue,
  mandatory,
  loadInitialData,
  regionId,
}) => {
  const dispatch = useDispatch();

  const aRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [loader, setLoader] = useState(false);
  const [allRegion, setAllRegion] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setImageUrl(defaultValue);
    }
  }, [defaultValue]);
  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValue(name, file);
      setSelectedImage({ name: file });
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const upload = () => {
    if (selectedImage?.name) {
      setLoader(true);
      const formData = new FormData();
      formData.append("image", selectedImage?.name);
      if (allRegion) {
        formData.append("regionId", -1);
      } else {
        formData.append("regionId", regionId);
      }
      axios
        .post("/backgroundImageUpdateFlavour", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            setImageUrl(response?.data?.data);
            loadInitialData();
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
          setLoader(false);
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          dispatch(
            showModal({
              msg: msg,
              error: true,
            })
          );
          setLoader(false);
        });
    }
  };
  const toggleSwitch = () => setAllRegion((prev) => !prev);
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <div>
          <label
            htmlFor="formFile"
            className="form-label"
            style={{ textTransform: "capitalize" }}
          >
            {label}
            {mandatory && (
              <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
            )}
            <Switch
              size="small"
              color={allRegion ? "success" : "error"}
              checked={allRegion}
              onChange={() => toggleSwitch()}
              style={{ marginBottom: "5px" }}
            />
            <span style={{ paddingLeft: "2px", fontSize: "12px" }}>
              (All Region)
            </span>
          </label>
          <div className="row">
            <div className="col-8">
              <input
                ref={aRef}
                className="form-control"
                name={name}
                type="file"
                control={control}
                onChange={fileHandler}
                accept="image/png, image/jpeg, image/webp"
              />
            </div>
            <div className="col-3 m-auto d-inline-flex justify-content-between">
              {imageUrl && !loader && (
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={upload}
                  disabled={loader}
                >
                  Upload
                </Button>
              )}
              {loader && (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 banner-card mt-3 p-0">
              {imageUrl ? (
                <PhotoProvider>
                  <PhotoView key={name} src={imageUrl}>
                    <img
                      src={imageUrl}
                      alt=""
                      className="w-100 h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </PhotoView>
                </PhotoProvider>
              ) : (
                <h1>2 : 1 ratio</h1>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};
export default memo(FlavourSectionBackgroundUpload);
