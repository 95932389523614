import React, { useEffect, useState } from "react";

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { subCategorySchema } from "../../../../app/schema";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PhotoProvider, PhotoView } from "react-photo-view";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../../redux/counterSlice";
import GeneralImageUpload from "../../../../components/form-components/GeneralImageUpload";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey, orange } from "@mui/material/colors";

function ProductsImage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { taxList, categoryList } = useSelector((state) => state.counter);

  const methods = useForm({
    resolver: yupResolver(subCategorySchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    Valid,
    formState: { errors, isDirty, isValid },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/product/" + id)
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = formData?.images[dataIndex]?.app_product_image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "index",
      label: "Primary",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const index = formData?.images[dataIndex]?.index;
          return (
            <Switch
              size="small"
              color={index === 1 ? "success" : "error"}
              checked={index === 1 ? true : false}
              onChange={() =>
                dispatch(
                  showConfirmModal({
                    msg: "make this Primary Image",
                    action: () =>
                      togglePrimaryImage(
                        formData?.images[dataIndex]?.product_image_id
                      ),
                  })
                )
              }
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const index = formData?.images[dataIndex]?.index;

          return (
            <div className="actionBtns">
              <Button
                disabled={index === 1 ? true : false}
                aria-label="edit"
                onClick={() =>
                  dispatch(
                    showConfirmModal({
                      msg: "Delete",
                      action: () =>
                        deleteClickHandler(
                          formData?.images[dataIndex]?.product_image_id
                        ),
                    })
                  )
                }
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: index === 1 ? grey[600] : orange[500] }}
                  color="error"
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    navigate("edit-product/" + item?.id);
  };
  const onSubmit = (data) => {
    console.log("data", data);
    saveForm(data);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/productSwitch/" + id)
      .then(function (response) {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const togglePrimaryImage = (id) => {
    setLoader(true);
    axios
      .get("/productImagePrimarySwitch/" + id)
      .then(function (response) {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      if (selectedRow?.id) {
        response = await axios.put("/product/" + selectedRow?.id, data);
      } else {
        response = await axios.post("/product", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setSelectedRow((prev) => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setOpen(false);
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };
  const deleteClickHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.get("/deleteImageProduct/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">New Image</DialogTitle>
        <Divider variant="middle" className="bg-primary" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <GeneralImageUpload
                name={id?.toString()}
                control={control}
                // label={"Product Image"}
                setValue={setValue}
                type="text"
                loadInitialData={loadInitialData}
                url="appImageUpdateProduct"
                formDataKey={{ id: "product_id", image: "product_image" }}
                dimension="Aspect Ratio 1:1"
                setOpenImage={setOpenImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    viewColumns: true,
    customSearch: false,
    print: true,
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex ">
            <ArrowBackIcon
              className="text-danger"
              sx={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h4 className="text-dark">Products Images</h4>
          </div>
          <MUIDataTable
            data={formData?.images}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setOpenImage(true);
                }}
                icon={<AddIcon />}
              >
                New Image
              </Button>
            }
          />
        </>
      )}
      <UpdateImageDialog />
    </>
  );
}

export default ProductsImage;
