import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  colors,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { Link } from "react-router-dom";

const ShopDetailsTable = ({ getShopOrderId }) => {
  return (
    <Grid item xs={12} className="my-5">
      <h5>Shop Details</h5>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="shop details table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Shop Name</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Mobile Number</TableCell>
              <TableCell align="center">Acceptance Status</TableCell>
              <TableCell align="center">Accepted At</TableCell>
              {getShopOrderId?.[0]?.picked_up_at && (
                <TableCell align="center">Delivery Status</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getShopOrderId.map((shop, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  <Link
                    to={`/view-shop-order/${shop?.shop_order_id}`}
                    replace
                  >
                    {shop.shop.shop_name}
                  </Link>
                </TableCell>
                <TableCell align="center">{shop.shop.address}</TableCell>
                <TableCell align="center">{shop.shop.mobile_no}</TableCell>
                <TableCell align="center">
                  {shop.shop_acceptance === 1 ? (
                    <Chip label="Accepted" color="success" />
                  ) : (
                    <Chip
                      label="Pending"
                      sx={{ background: "orange", color: "white" }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {shop.accepted_at ? shop.accepted_at : "-"}
                </TableCell>

                {shop.picked_up_at && (
                  <TableCell align="center">
                    <span className="text-info status_icon m-1">
                      <i
                        className="fa fa-smile-o mr-2 mobile-icon"
                        aria-hidden="true"
                      ></i>
                      <span className="window-text">Customer Picked</span>
                    </span>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ShopDetailsTable;
