import React, { memo, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import { LinearProgress, Paper } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { showModal } from "../../app/store/slice/counterSlice";
import { useDispatch } from "react-redux";

const DispatchImageUpload = ({
  loadInitialData,
  locationDetailsId,
  getDispatchImages,
  defaultValue,
  selectedRow,
  dispatchOrderId,
  dispatchPrepareImageId,
}) => {
  const dispatch = useDispatch();

  const aRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setImageUrl(defaultValue);
    }
  }, [defaultValue]);
  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage({ name: file });
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const upload = () => {
    if (selectedImage?.name) {
      setLoader(true);
      const formData = new FormData();
      formData.append("location_details_id", locationDetailsId);
      if (dispatchOrderId == 0) {
        formData.append("dish_image", selectedImage?.name);
        formData.append("product_id", selectedRow?.product_id);
        formData.append("dispatch_order_id", dispatchOrderId);
        formData.append(
          "image_name",
          selectedRow?.product_details?.[0]?.product_name
        );
      } else {
        formData.append("dish_image", selectedImage?.name);
        formData.append("product_id", selectedRow?.product_id);
        formData.append("dispatch_order_id", dispatchOrderId);
        formData.append(
          "image_name",
          selectedRow?.product_details?.[0]?.product_name
        );
        // formData.append(
        //   "dispatch_prepare_image_id",
        //   selectedRow?.dispatch_prepare_image_id || null
        // );
        formData.append("dispatch_prepare_image_id", null);
      }
      axios
        .post("/dispatchApproveImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            setImageUrl(response?.data?.data);
            setLoader(false);
            loadInitialData();
            getDispatchImages();
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
          setLoader(false);
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          dispatch(
            showModal({
              msg: msg,
              error: true,
            })
          );
          setLoader(false);
        });
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        margin: "auto",
        flexGrow: 1,
      }}
    >
      <div>
        <label
          htmlFor="formFile"
          className="form-label"
          style={{ textTransform: "capitalize", fontWeight: 600 }}
        >
          Image Upload
        </label>
        <div className="row">
          <div className="col-9">
            <input
              ref={aRef}
              className="form-control"
              name={"image"}
              type="file"
              onChange={fileHandler}
              accept="image/png, image/jpeg, image/webp"
            />
          </div>
          <div className="col-3 m-auto d-inline-flex justify-content-between">
            {imageUrl && !loader && (
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={upload}
                disabled={loader}
              >
                Upload
              </Button>
            )}

            {loader && (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 banner-card mt-4 p-0"
            style={{ height: "150px" }}
          >
            {imageUrl && (
              <PhotoProvider>
                <PhotoView src={imageUrl}>
                  <img
                    src={imageUrl}
                    alt=""
                    style={{
                      cursor: "pointer",
                      width: "75%",
                      maxWidth: "fit-content",
                      height: "100%",
                    }}
                  />
                </PhotoView>
              </PhotoProvider>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};
export default memo(DispatchImageUpload);
