import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { hideModal } from "../../redux/counterSlice";

export default function AlertDialog() {
  const dispatch = useDispatch();
  const { message, error, flag } = useSelector((state) => state.counter.modal);
  const [errorType, setErrorType] = React.useState(false);

  React.useEffect(() => {
    setErrorType(error);
  }, [error]);
  return (
    <div>
      <Dialog
        open={flag}
        onClose={() => dispatch(hideModal({ msg: "", error: false }))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {errorType && (
          <DialogTitle
            id="alert-dialog-title"
            className="bg-danger text-white text-center"
          >
            Message
          </DialogTitle>
        )}
        {!errorType && (
          <DialogTitle
            id="alert-dialog-title"
            className="bg-success text-white text-center"
          >
            Message
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ marginTop: 3, color: "black", whiteSpace: "pre-line" }}
          >
            {/* <CheckCircleOutlineIcon color="success" fontSize="large" /> &emsp;{" "} */}
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(hideModal({ msg: "", error: false }))}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
