import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../../../app/schema";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { useDispatch, useSelector } from "react-redux";
import FormTextEditor from "../../../components/form-components/FormTextEditor";
import { FormAutoComplete } from "../../../components/form-components/FormAutoComplete";
import {
  defaultDropDown,
  parseArray,
  parseArrayToString,
} from "../../../app/utlils";
import { FormInputMultiCheckbox } from "../../../components/form-components/FormInputMultiCheckbox";
import ProductVariation from "./ProductVariation";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { showModal } from "../../../redux/counterSlice";
import Loader from "../../../components/loader/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormInputTextArea } from "../../../components/form-components/FormInputTextArea";
import ProductRegionBasedAttributes from "./ProductRegionBasedAttributes";

function ProductForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    taxList,
    categoryList,
    subCategoryList,
    unitList,
    flavourList,
    regionList,
  } = useSelector((state) => state.counter);

  const [productData, setProductData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [weightList, setWeightList] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const methods = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      product_name: productData?.product_name,
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    resetField,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    const { cod, ...remainingPayload } = data;
    const payload = {
      ...remainingPayload,
      COD_egg: data?.cod?.includes("egg") ? 1 : 0,
      COD_eggless: data?.cod?.includes("eggless") ? 1 : 0,
      region: parseArray(data?.region)?.toString(),
      flavour: parseArray(data?.flavour)?.toString(),
    };
    saveForm(payload);
  };

  const selectedCategory = watch("category_id");
  const selectedSubCategory = watch("sub_category_id");
  const productName = watch("product_name");
  const unit = watch("unit_id");
  const selectedRegions = watch("region");
  useEffect(() => {
    setLoader(true);
    const filteredSubCategories = subCategoryList?.filter(
      (item) => item?.category_id === selectedCategory
    );
    setFilteredSubCategories((prev) => filteredSubCategories);
    // setValue("sub_category_id", "");
    setLoader(false);
  }, [selectedCategory, productData]);
  useEffect(() => {
    if (selectedSubCategory > 0) {
      const selectedSubCategoryData = subCategoryList.find(
        (item) => item.id === selectedSubCategory
      );
      if (selectedSubCategoryData) {
        const { hsn, tax_id } = selectedSubCategoryData;
        setValue("hsn", hsn);
        setValue("tax_id", tax_id);
      }
    }
  }, [selectedSubCategory, subCategoryList]);
  useEffect(() => {
    loadWeightDataById(unit);
  }, [unit]);

  const saveForm = async (data) => {
    try {
      setLoader(true);
      let response;
      if (id > 0) {
        response = await axios.put("/product/" + id, data);
      } else {
        response = await axios.post("/product", data);
      }
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        navigate(-1);
        //  reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const getSKU = (weightId) => {
    let selectedWeight = weightList?.find(
      (item) => item.weight_id == weightId
    )?.weight_name;
    const unitName = unitList?.find((item) => item.unit_id == unit)?.unit_name;
    const subCategoryName = subCategoryList?.find(
      (item) => item.id == selectedSubCategory
    )?.sub_category_name;

    //SKU Constaints
    let subCategoryShortName = subCategoryName
      ?.split(/\s/)
      ?.reduce((response, word) => (response += word?.slice(0, 1)), "")
      ?.toUpperCase();
    var productShortName = productName
      ?.split(/\s/)
      ?.reduce((response, word) => (response += word?.slice(0, 1)), "")
      ?.toUpperCase();
    if (unitName == "Kgs") {
      selectedWeight =
        selectedWeight.length == 1 ? selectedWeight + ".0" : selectedWeight;
    }
    var SKU =
      subCategoryShortName + "_" + productShortName + "_" + selectedWeight;
    return SKU;
  };
  const loadWeightDataById = (unitId) => {
    if (unitId) {
      setLoader(true);
      axios
        .get("/getAllActiveWeightListByUnitId/" + unitId)
        .then(function (response) {
          setWeightList(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  const loadInitialData = () => {
    if (id > 0) {
      reset();
      setLoader(true);
      axios
        .get("/product/" + id)
        .then(function (response) {
          setProductData(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return loader ? (
    <Loader />
  ) : (
    <div>
      <div className="d-flex ">
        <ArrowBackIcon
          className="text-danger"
          sx={{ marginRight: "1rem", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <h4 className="text-primary">{id ? "Edit Product" : "New Product"}</h4>
      </div>

      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <Grid container columnSpacing={{ xs: 4 }}>
          <Grid item xs={6}>
            <Controller
              defaultValue={productData?.category_id}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl
                    fullWidth
                    error={errors?.["category_id"]?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="category_id_label"> Category</InputLabel>
                    <Select
                      labelId="category_id_label"
                      id={`category_id_select`}
                      // value={value || productData?.category_id}
                      value={value}
                      label="Category"
                      onChange={onChange}
                    >
                      <MenuItem value={""} key={0}>
                        --SELECT--
                      </MenuItem>
                      {categoryList?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
              control={control}
              name={"category_id"}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              defaultValue={productData?.sub_category_id}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl
                    fullWidth
                    error={errors?.["sub_category_id"]?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="sub_category_id_label">
                      Sub Category
                    </InputLabel>
                    <Select
                      labelId="sub_category_id_label"
                      id={`sub_category_id_select`}
                      // value={value || productData?.sub_category_id}
                      value={value}
                      label="Sub Category"
                      onChange={onChange}
                    >
                      <MenuItem value={""} key={0}>
                        --SELECT--
                      </MenuItem>
                      {filteredSubCategories?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.sub_category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
              control={control}
              name={"sub_category_id"}
            />
            {/* <FormInputDropdown
              name="sub_category_id"
              control={control}
              label="Sub Category"
              type="single"
              options={filteredSubCategories?.map((item) => ({
                id: item?.id,
                name: item?.sub_category_name,
              }))}
              errors={errors}
              defaultValue={productData?.sub_category_id}
              mandatory="true"
            /> */}
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="product_name"
              control={control}
              label="Product Name"
              errors={errors}
              mandatory="true"
              defaultValue={productData?.product_name}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              defaultValue={productData?.unit_id}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl
                    fullWidth
                    error={errors?.["unit_id"]?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="unit_id_label"> Unit</InputLabel>
                    <Select
                      labelId="unit_id_label"
                      id={`unit_id_select`}
                      value={value || productData?.unit_id}
                      label="Category"
                      onChange={onChange}
                    >
                      <MenuItem value={""} key={0}>
                        --SELECT--
                      </MenuItem>
                      {unitList?.map((item) => (
                        <MenuItem value={item?.unit_id} key={item?.unit_id}>
                          {item?.unit_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
              control={control}
              name={"unit_id"}
            />
          </Grid>

          <Grid item xs={6}>
            {/* <FormInputText
              name="hsn"
              control={control}
              label="HSN / SAC Code"
              errors={errors}
              mandatory="true"
              defaultValue={productData?.hsn}
            /> */}
            <Controller
              name={`hsn`}
              control={control}
              defaultValue={productData?.hsn || ""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="HSN / SAC Code"
                  variant="outlined"
                  onChange={onChange}
                  value={value || ""}
                  error={error?.message ? true : false}
                  margin="normal"
                  defaultValue={productData?.hsn}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="tax_id"
              control={control}
              defaultValue={productData?.tax_id || ""}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="tax_id_label">Tax</InputLabel>
                  <Select
                    labelId="tax_id_label"
                    id="tax_id_select"
                    value={value}
                    onChange={onChange}
                    label="Tax"
                  >
                    <MenuItem value="">-- SELECT --</MenuItem>
                    {taxList?.map((item) => (
                      <MenuItem value={item.tax_id} key={item.tax_id}>
                        {`${item.tax_percentage} %`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormAutoComplete
              name="region"
              control={control}
              label="Region"
              multiple={true}
              dataArray={regionList?.map((item) => ({
                value: item?.region_id,
                label: item?.city?.name,
              }))}
              errors={errors}
              defaultValue={productData?.region?.map((item) => ({
                value: item?.id,
                label: item?.itemName?.city?.name,
              }))}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={6}>
            <FormAutoComplete
              name="flavour"
              control={control}
              label="Flavour"
              multiple={true}
              errors={errors}
              defaultValue={productData?.flavour?.map((item) => ({
                value: item?.id,
                label: item?.itemName,
              }))}
              dataArray={flavourList?.map((item) => ({
                value: item?.flavour_id,
                label: item?.flavour_name,
              }))}
              mandatory="true"
            />
          </Grid>
          {/* <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <ProductRegionBasedAttributes
              selectedRegions={selectedRegions || []}
              control={control}
              getSKU={getSKU}
              weightList={weightList}
              setValue={setValue}
              getValues={getValues}
              type={id > 0 ? "UPDATE" : "CREATE"}
            />
          </Grid> */}
          <Grid item xs={3} sx={{ marginTop: "8px" }}>
            <FormInputDropdown
              name="new_product"
              control={control}
              label="New Product"
              type="single"
              options={defaultDropDown}
              errors={errors}
              defaultValue={productData?.new_product}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={3} sx={{ marginTop: "8px" }}>
            <FormInputDropdown
              name="best_selling"
              control={control}
              label="Best Seller"
              type="single"
              options={defaultDropDown}
              errors={errors}
              defaultValue={productData?.best_selling}
              mandatory="true"
            />
          </Grid>
          <Grid item xs={3} sx={{ marginTop: "8px" }}>
            <FormInputText
              name="ratings"
              control={control}
              label="Ratings"
              errors={errors}
              mandatory="true"
              defaultValue={productData?.ratings}
            />
          </Grid>
          <Grid item xs={3} sx={{ marginTop: "8px" }}>
            <FormInputMultiCheckbox
              control={control}
              setValue={setValue}
              name={"cod"}
              label={"Cash On Delivery"}
              checkBoxoptions={[
                {
                  label: "Egg",
                  value: "egg",
                },
                {
                  label: "Eggless",
                  value: "eggless",
                },
              ]}
            />
          </Grid>
        </Grid>
        <Divider
          variant="middle"
          className="text-primary"
          sx={{
            margin: "32px auto",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Descriptions
        </Divider>
        <Grid container columnSpacing={{ xs: 5 }}>
          {/* <Grid item xs={6}>
            <FormTextEditor
              name="product_description"
              control={control}
              label="Product Description"
              errors={errors}
              defaultValue={productData?.product_description}
            />
          </Grid> */}
          <Grid item xs={6}>
            <FormInputTextArea
              name="short_description"
              control={control}
              label="Description"
              minRows={2}
              maxRows={15}
              placeholder={"Description"}
              errors={errors}
              mandatory="true"
              defaultValue={productData?.short_description}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInputTextArea
              name="product_description"
              control={control}
              label="Care Instruction"
              minRows={2}
              maxRows={15}
              placeholder={"Care Instruction"}
              errors={errors}
              mandatory="true"
              defaultValue={productData?.product_description}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <FormTextEditor
              name="short_description"
              control={control}
              label="Short Description"
              errors={errors}
              defaultValue={productData?.short_description}
            />
          </Grid> */}
        </Grid>
        <Divider
          variant="middle"
          className="text-primary"
          sx={{ margin: "32px auto", fontSize: "16px", fontWeight: "bold" }}
        >
          Pricing
        </Divider>
        <Grid container columnSpacing={{ xs: 5 }}>
          <Grid item xs={6}>
            <FormInputText
              name="product_price"
              control={control}
              label="Base Egg Price"
              errors={errors}
              mandatory="true"
              defaultValue={productData?.product_price}
            />
            <Typography variant="subtitle2" gutterBottom color={"error"}>
              (NOTE: Base Price For 0.5 Kg)
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormInputText
              name="base_eggless_price"
              control={control}
              label="Base Eggless Price"
              errors={errors}
              mandatory="true"
              defaultValue={productData?.base_eggless_price}
            />
            <Typography variant="subtitle2" gutterBottom color={"error"}>
              (NOTE: Base Price For 0.5 Kg)
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "24px" }}>
            <ProductVariation
              variationValue={productData?.variation || []}
              control={control}
              getSKU={getSKU}
              weightList={weightList}
              setValue={setValue}
              getValues={getValues}
              type={id > 0 ? "UPDATE" : "CREATE"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "24px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              sx={{ paddingLeft: "32px", paddingRight: "32px" }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default ProductForm;
