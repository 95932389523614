import React, { useEffect, useState } from "react";
import {
  styled,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";

import { useDispatch } from "react-redux";
import Loader from "../../components/loader/Loader";
import axios from "axios";
import toast from "react-hot-toast";

import {
  storeCategoryList,
  storeFlavourList,
  storeRegionList,
  storeSubCategoryList,
  storeTaxList,
  storeUnitList,
  storeWeightList,
} from "../../redux/counterSlice";
import { BarChart } from "@mui/x-charts/BarChart";

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function DashboardIndex() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [type, setType] = useState("daily");

  const loadInitialData = async () => {
    try {
      setLoader(true);
      const [
        taxResponse,
        categoryList,
        subCategoryList,
        flavourList,
        weightList,
        unitList,
        regionList,
      ] = await axios.all([
        axios.get("/getAllActiveTaxList"),
        axios.get("/category"),
        axios.get("/subcategory"),
        axios.get("/getAllActiveFlavourList"),
        axios.get("/getAllActiveWeightList"),
        axios.get("/getAllActiveUnitList"),
        axios.get("/getAllActiveRegionList"),
      ]);
      taxResponse?.data?.data?.length > 0 &&
        dispatch(storeTaxList(taxResponse?.data?.data));
      categoryList?.data?.data?.length > 0 &&
        dispatch(storeCategoryList(categoryList?.data?.data));
      subCategoryList?.data?.data?.length > 0 &&
        dispatch(storeSubCategoryList(subCategoryList?.data?.data));
      flavourList?.data?.data?.length > 0 &&
        dispatch(storeFlavourList(flavourList?.data?.data));
      weightList?.data?.data?.length > 0 &&
        dispatch(storeWeightList(weightList?.data?.data));
      unitList?.data?.data?.length > 0 &&
        dispatch(storeUnitList(unitList?.data?.data));
      regionList?.data?.data?.length > 0 &&
        dispatch(storeRegionList(regionList?.data?.data));
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoader(false);
    }
  };
  const dashboardData = async () => {
    try {
      setLoader(true);
      const res = await axios.get("/dashboard/" + type);
      setFormData(res?.data?.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    dashboardData();
  }, [type]);
  useEffect(() => {
    loadInitialData();
  }, []);

  const TopSellingCakes = () => {
    return (
      <TableContainer component={Paper} className="top-selling-cakes">
        <Table aria-label="driver details table" dense>
          <TableHead>
            <TableRow>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData?.top_selling_products?.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item?.product_name}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={item?.total_quantity}
                    color="primary"
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const parseColumnLabel = (string) => {
    const regex = /\d{4}-(\w{3})-(\d{1,2})/;

    // Execute the regular expression and extract the month and date
    const matches = regex.exec(string);

    if (matches) {
      const month = matches[1]; // Month abbreviation (e.g., Apr)
      const date = matches[2]; // Date (e.g., 21)

      return `${month}-${date}`;
    }
  };
  return loader ? (
    <Loader />
  ) : (
    <>
      {/* <h4 className="text-primary mt-4">Welcome</h4>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
          width: "100%",
        }}
        className="bg-light"
      >
        <h6>
          <b>Blaack Forest Cakes</b>
        </h6>
        <p className="mb-0">Contact No. : +91 84899 55500</p>
        <p> email : online@blaackforestcakes.com</p>
      </Paper> */}
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Dashboard
            </Typography>
          </Grid>
          {/* <Grid item xs={4} sx={{ justifyContent: "end", display: "flex" }}>
            <ToggleButtonGroup
              color="primary"
              value={type}
              size="small"
              exclusive
              onChange={(e) => setType(e?.target?.value)}
              aria-label="Platform"
            >
              <ToggleButton value="daily">Daily</ToggleButton>
              <ToggleButton value="weekly">Weekly</ToggleButton>
              <ToggleButton value="monthly">Monthly</ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
          <Grid item xs={4}>
            <Paper className={`${classes.paper} countItem`}>
              <Typography variant="h6" gutterBottom>
                Daily Count
              </Typography>
              <Typography variant="subtitle1">
                {formData?.orders_today}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={`${classes.paper} countItem`}>
              <Typography variant="h6" gutterBottom>
                Monthly Count
              </Typography>
              <Typography variant="subtitle1">
                {formData?.orders_this_month}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={`${classes.paper} countItem`}>
              <Typography variant="h6" gutterBottom>
                Yearly
              </Typography>
              <Typography variant="subtitle1">
                {formData?.orders_this_year}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {formData?.bar_chart?.length > 0 && (
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data:
                      formData?.bar_chart?.map((item) =>
                        parseColumnLabel(item?.order_submitted_at)
                      ) || [],
                  },
                ]}
                series={[
                  {
                    data:
                      formData?.bar_chart?.map(
                        (item) => item?.total_quantity
                      ) || [],
                  },
                ]}
                height={300}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <TopSellingCakes />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DashboardIndex;
