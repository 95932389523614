import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Switch,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, red } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import { locationSchema } from "../../../app/schema";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputTextArea } from "../../../components/form-components/FormInputTextArea";
import {
  GpsOffOutlined,
  LocationOffOutlined,
  MyLocationOutlined,
} from "@mui/icons-material";

function Location() {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(locationSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [region, setRegion] = useState([]);
  const [allRegion, setAllRegion] = useState([]);

  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const loadInitialData = async () => {
    setLoader(true);
    axios
      .all([
        axios.get("/getAllLocationDetails"),
        axios.get("/getAllActiveRegionList"),
        axios.get("/getAllRegionList"),
      ])
      .then(function (response) {
        setFormData(response[0]?.data?.data);
        setRegion(response[1]?.data?.data);
        setAllRegion(response[2]?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);
  const deleteClickHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.get("/deleteLocationDetails/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
      setConfirmOpen(false);
    }
  };

  const columns = [
    {
      name: "location_details_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.region?.[0]?.city?.name;
          return value;
        },
      },
    },
    {
      name: "location_code",
      label: "Location Code",
    },
    {
      name: "location_name",
      label: "Location",
    },
    {
      name: "pincode",
      label: "Pincode",
    },
    {
      name: "mobile_no",
      label: "Mobile No",
    },
    {
      name: "coordinates",
      label: "Coordinates",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const latitude = formData[dataIndex]?.latitude;
          const longitude = formData[dataIndex]?.longitude;

          return latitude && longitude ? (
            <Link
              target="_blank"
              rel="noopener"
              color="primary"
              href={`https://maps.google.com/?q=${latitude},${longitude}`}
            >
              <Tooltip title={`${latitude},${longitude}`}>
                <IconButton>
                  <MyLocationOutlined sx={{ color: "red", fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </Link>
          ) : (
            <GpsOffOutlined sx={{ color: "red", fontSize: "20px" }} />
          );
        },
      },
    },
    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                dispatch(
                  showConfirmModal({
                    msg: status === 1 ? "deactivate" : "activate",
                    action: () =>
                      toggleStatus(formData[dataIndex]?.location_details_id),
                  })
                );
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="delete"
                onClick={() => {
                  dispatch(
                    showConfirmModal({
                      msg: "delete",
                      action: () =>
                        deleteClickHandler(
                          formData[dataIndex]?.location_details_id
                        ),
                    })
                  );
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    color: status === 1 ? grey[600] : red[500],
                  }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/locationSwitch/" + id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const saveForm = async (data) => {
    setLoader(true);

    try {
      let response;
      const cityId = data?.region_id;
      const regionObj = region?.find((item) => item?.city_id == cityId);

      data = {
        ...data,
        region_id: regionObj?.region_id,
      };
      if (selectedRow?.location_details_id) {
        response = await axios.post(
          "/updateLocationDetails",
          { ...data, location_details_id: selectedRow?.location_details_id },
          data
        );
      } else {
        response = await axios.post("/createLocationDetails", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow(() => null);
        reset();
      } else {
        setOpen(false);
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
    }
  };

  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Location" : "New Location"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={6}>
              <FormInputDropdown
                name="region_id"
                control={control}
                label={"City"}
                type="single"
                options={region?.map((item) => ({
                  id: item?.city?.id,
                  name: item?.city?.name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.region?.[0]?.city_id}
                mandatory="true"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="location_code"
                control={control}
                label="Location Code"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.location_code}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="location_name"
                control={control}
                label="Location Name"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.location_name}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="mobile_no"
                control={control}
                label="Mobile No"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.mobile_no}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="pincode"
                control={control}
                label="Pincode"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.pincode}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputTextArea
                name="address"
                control={control}
                label="Address"
                minRows={2}
                maxRows={4}
                placeholder={"Location Address"}
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.address}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="latitude"
                control={control}
                label="Latitude"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.latitude}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                name="longitude"
                control={control}
                label="Longitude"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.longitude}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
    enableNestedDataAccess: ".",
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Location</h4>

          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                icon={<AddIcon />}
              >
                New Location
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Location;
