import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { FormInputDropdown } from "../../../components/form-components/FormInputDropdown";
import { FormInputText } from "../../../components/form-components/FormInputText";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { preparationHours } from "../../../app/utlils";

const initialRows = [];

export default function ProductVariation({
  variationValue,
  control,
  getSKU,
  weightList,
  getValues,
  setValue,
}) {
  const [variation, setVariation] = React.useState(variationValue);

  const addVariationHandler = () => {
    setVariation((prev) => [
      ...prev,
      {
        weight: "",
        egg: "",
        eggLess: "",
        eggPreparation: 0,
        egglessPreparation: 0,
        sku: "",
      },
    ]);
  };
  const removeVariations = (index) => {
    const newVariation = [...variation];
    newVariation.splice(index, 1);
    setVariation(newVariation);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        setCellProps: () => ({
          style: { width: "30px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              name={`variation[${dataIndex}].id`}
              control={control}
              label="ID"
              disabled
              mandatory="true"
              disable={true}
              defaultValue={variation[dataIndex].variation_id}
            />
          );
        },
      },
    },
    {
      name: "weight_id",
      label: "Weight",
      options: {
        setCellProps: () => ({
          style: { width: "250px" },
        }),

        customBodyRenderLite: (dataIndex) => {
          return (
            <Controller
              defaultValue={variation[dataIndex].weight_id}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormControl
                    fullWidth
                    error={error?.message ? true : false}
                    margin="normal"
                  >
                    <InputLabel id="weight_id_label"> Weight</InputLabel>
                    <Select
                      labelId="weight_id_label"
                      id={`variation[${dataIndex}].weight`}
                      defaultValue={variation[dataIndex].weight_id}
                      label="Weight"
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        onChange(event);
                        const sku = getSKU(selectedValue);
                        setValue(`variation[${dataIndex}].sku`, sku);
                      }}
                    >
                      <MenuItem value={""} key={0}>
                        --SELECT--
                      </MenuItem>
                      {weightList?.map((item) => (
                        <MenuItem value={item?.weight_id} key={item?.weight_id}>
                          {`${item.weight_name} ${item?.unit?.unit_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
              control={control}
              name={`variation[${dataIndex}].weight`}
            />
          );
        },
      },
    },
    {
      name: "egg",
      label: "Egg",
      options: {
        setCellProps: () => ({
          style: { width: "150px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              name={`variation[${dataIndex}].egg`}
              control={control}
              label="Egg"
              mandatory="true"
              defaultValue={variation[dataIndex].egg}
            />
          );
        },
      },
    },
    {
      name: "eggLess",
      label: "Eggless",

      options: {
        setCellProps: () => ({
          style: { width: "150px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              name={`variation[${dataIndex}].eggLess`}
              control={control}
              label="Eggless"
              mandatory="true"
              defaultValue={variation[dataIndex].eggLess}
            />
          );
        },
      },
    },

    {
      name: "eggPreparation",
      label: "Egg Preparation",

      options: {
        setCellProps: () => ({
          style: { width: "250px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputDropdown
              name={`variation[${dataIndex}].eggPreparation`}
              control={control}
              label="Egg Preparation"
              type="single"
              options={preparationHours?.map((item) => ({
                id: item?.id,
                name: item?.name,
              }))}
              defaultValue={variation[dataIndex].egg_preparation || 0}
              mandatory="true"
            />
          );
        },
      },
    },
    {
      name: "egglessPreparation",
      label: "Eggless Preparation",

      options: {
        setCellProps: () => ({
          style: { width: "250px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputDropdown
              name={`variation[${dataIndex}].egglessPreparation`}
              control={control}
              label="Eggless Preparation"
              type="single"
              options={preparationHours?.map((item) => ({
                id: item?.id,
                name: item?.name,
              }))}
              mandatory="true"
              defaultValue={variation[dataIndex].eggless_preparation || 0}
            />
          );
        },
      },
    },
    {
      name: "sku",
      label: "SKU",
      options: {
        setCellProps: () => ({
          style: { width: "150px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <Controller
              name={`variation[${dataIndex}].sku`}
              control={control}
              defaultValue={variation[dataIndex].sku || ""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="SKU"
                  variant="outlined"
                  onChange={onChange}
                  value={value || ""}
                  error={error?.message ? true : false}
                  margin="normal"
                  defaultValue={variation[dataIndex].sku}
                />
              )}
            />
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => removeVariations(variation[dataIndex]?.id)}
              >
                <DeleteOutlineOutlinedIcon color="error" fontSize="medium" />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
  };
  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <MUIDataTable
        className="bg-light"
        data={variation || []}
        columns={columns}
        options={options}
        columnVisibilityModel={{
          variation_id: false,
        }}
        title={
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => addVariationHandler()}
            icon={<AddIcon />}
          >
            New Variation
          </Button>
        }
      />
    </Box>
  );
}
