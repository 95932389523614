import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const FormAutoComplete = ({
  name,
  control,
  label,
  dataArray,
  defaultValue,
  mandatory,
  errors,
  multiple,
}) => {
  // console.log(defaultValue);
  // console.log(dataArray)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        if (multiple) {
          multiple = true;
        }
        return (
          <Autocomplete
            sx={{ marginTop: "16px" }}
            control
            multiple={multiple}
            onChange={(_, data) => {
              onChange(data);
              return data;
            }}
            id="tags-standard"
            options={dataArray}
            // isOptionEqualToValue={(option, value) => option?.id == value?.id}
            getOptionLabel={(option) => option?.label}
            defaultValue={defaultValue}
            disableCloseOnSelect={multiple ? true : false}
            renderOption={(props, option, { selected }) => {
              const isChecked =
                multiple &&
                value?.findIndex((item) => item?.value == option?.value);
              const selectByDefault = isChecked != -1 ? true : false;
              return (
                <li {...props}>
                  {multiple && (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected || selectByDefault}
                    />
                  )}
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={label}
                error={errors?.[name]?.message ? true : false}
              />
            )}
          />
        );
      }}
    />
  );
};
