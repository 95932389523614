import React from "react";
import {
  Paper,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import { PhotoProvider, PhotoView } from "react-photo-view";

function OrderListTable({ productList, addonList, priceDetails }) {
  return (
    <>
      <h5 className="text-primary ">Product Details</h5>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S. No</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Weight</TableCell>
              <TableCell align="center">Flavour</TableCell>
              <TableCell align="center">Message Cake</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productList?.map((row, index) => {
              const imageUrl =
                row?.product_details?.[0]?.images?.[0]?.app_product_image;
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {+index + 1}
                  </TableCell>
                  <TableCell align="center">
                    <PhotoProvider>
                      <PhotoView key={index} src={imageUrl}>
                        <img
                          src={imageUrl}
                          alt=""
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </PhotoView>
                    </PhotoProvider>
                  </TableCell>
                  <TableCell align="center">
                    {row?.product_details?.[0]?.product_name}
                    <br />
                    {row?.egg_or_eggless == 1 && (
                      <span style={{ color: "red" }}>( Eggless )</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row?.variation?.weight?.[0]?.weight_name} &nbsp;
                    {row?.variation?.weight?.[0]?.unit?.unit_name}
                  </TableCell>
                  <TableCell align="center">
                    {row?.flavour?.flavour_name != "Default"
                      ? row?.flavour?.flavour_name
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.message_on_cake || "-"}
                  </TableCell>
                  <TableCell align="center">
                    ₹ {row.product_discount_price}
                  </TableCell>
                  <TableCell align="center">{row?.product_quantity}</TableCell>
                  <TableCell align="center">₹ {row?.product_total}</TableCell>
                </TableRow>
              );
            })}
            {addonList?.map((row, index) => {
              const imageUrl = row?.image;
              return (
                <TableRow
                  key={productList?.length + +index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {+productList?.length + +index + 1}
                  </TableCell>
                  <TableCell align="center">
                    <PhotoProvider>
                      <PhotoView key={index} src={imageUrl}>
                        <img
                          src={imageUrl}
                          alt=""
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </PhotoView>
                    </PhotoProvider>
                  </TableCell>
                  <TableCell align="center">{row?.product_name}</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">₹ {row.price}</TableCell>
                  <TableCell align="center">{row?.quantity}</TableCell>
                  <TableCell align="center">₹ {row?.total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default OrderListTable;
