import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, orange } from "@mui/material/colors";
import { FormInputText } from "../../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import { categorySchema } from "../../../app/schema";
import { showModal } from "../../../app/store/slice/counterSlice";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CategoryImageUpload from "../../../components/form-components/CategoryImageUpload";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { storeCategoryList } from "../../../redux/counterSlice";
import { FormColorPicker } from "../../../components/form-components/FormColorPicker";

function Category() {
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: yupResolver(categorySchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [colors, setColors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/category")
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
        reloadStore();
        handleClose();
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  const reloadStore = () => {
    axios
      .get("/getActiveCategory")
      .then(function (response) {
        response?.data?.data?.length > 0 &&
          dispatch(storeCategoryList(response?.data?.data));
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "app_category_image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = formData[dataIndex]?.app_category_image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "category_name",
      label: "Name",
    },

    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                setSelectedRow(() => ({
                  ...formData[dataIndex],
                  action: () => toggleStatus(formData[dataIndex]?.id),
                  confirmLabel: status === 1 ? "Deactivate" : "Activate",
                }));
                setConfirmOpen(true);
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Edit Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({ ...formData[dataIndex] }));
                  setOpenImage(true);
                }}
              >
                <AddPhotoAlternateIcon
                  sx={{ color: status === 1 ? grey[600] : orange[500] }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "Mini Banner",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({ ...formData[dataIndex] }));
                  setOpenImage(true);
                }}
              >
                <AddPhotoAlternateIcon
                  sx={{ color: status === 1 ? grey[600] : orange[500] }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({
                    ...formData[dataIndex],
                    action: () => deleteClickHandler(formData[dataIndex]?.id),
                    confirmLabel: "Delete",
                  }));
                  setConfirmOpen(true);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: status === 1 ? grey[600] : "red" }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/categorySwitch/" + id)
      .then(function (response) {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        setConfirmOpen(false);
      });
  };
  const saveForm = async (data) => {
    try {
      setLoader(true);
      let response;
      if (selectedRow?.id) {
        response = await axios.put("/category/" + selectedRow?.id, data);
      } else {
        response = await axios.post("/category", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();

        setSelectedRow((prev) => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
    }
  };

  const deleteClickHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.delete("/category/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
      setConfirmOpen(false);
    }
  };

  const CreateEditDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Category" : "New Category"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <FormInputText
                name="category_name"
                control={control}
                label="Category Name"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.category_name}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="category_description"
                control={control}
                label="Category Description"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.category_description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormColorPicker
                name="bg_color"
                control={control}
                label="Background Color"
                type="single"
                errors={errors}
                defaultValue={selectedRow?.color_id}
                mandatory="true"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Update Image</DialogTitle>
        <Divider variant="middle" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <CategoryImageUpload
                status={selectedRow?.app_category_image}
                name={selectedRow?.id?.toString()}
                control={control}
                label={selectedRow?.category_name}
                setValue={setValue}
                type="text"
                defaultValue={selectedRow?.app_category_image}
                loadInitialData={loadInitialData}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    customSearch: false,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h5 className="text-dark mt-2 screen-heading">Category</h5>
          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                size="small"
                icon={<AddIcon />}
              >
                New Category
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <UpdateImageDialog />{" "}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Category;
