import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { showModal } from "../../app/store/slice/counterSlice";
import GeneralBottomBannerUpload from "../../components/form-components/GeneralBottomBannerUpload";
import { FormInputText } from "../../components/form-components/FormInputText";
import AddIcon from "@mui/icons-material/Add";

function BottomBanner({ regionId }) {
  const dispatch = useDispatch();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [open, setOpen] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const onSubmit = (data) => save(data);

  const loadInitialData = () => {
    axios
      .get("/getHomeBottomBanner/" + regionId)
      .then(function (response) {
        setFormData(response?.data?.data);

        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  const save = async (data) => {
    setLoader(true);
    try {
      const payload = { ...data, region_id: regionId };
      const response = await axios.post("/createBottomBanner", payload);
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setOpen(false);
      setLoader(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle className="text-primary">New Banner</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <FormInputText
                name="name"
                control={control}
                label="Banner Name"
                errors={errors}
                mandatory="true"
                // defaultValue={selectedRow?.product_name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
  return loader ? (
    <Loader />
  ) : (
    <>
      {/* <h6 className="text-dark mt-2 screen-heading">Bottom Banner Upload</h6> */}
      <Grid item xs={12}>
        <ul
          className="w-100 d-inline-flex justify-content-around"
          style={{ color: "red" }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpen(true)}
            icon={<AddIcon />}
          >
            New Banner
          </Button>

          <li>Please upload only jpg, jpeg, png.</li>
          <li>File size shall not exceed 70kb</li>
          <li>Dimension : 4:1 ratio</li>
        </ul>
      </Grid>
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 5 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {formData?.length > 0 ? (
          formData?.map((item, index) => {
            return (
              <Grid item xs={12} md={4} lg={4} xl={4} key={index}>
                <GeneralBottomBannerUpload
                  status={item?.active_status}
                  name={"name"}
                  control={control}
                  label={item?.name || "Bottom Banner"}
                  setValue={setValue}
                  type="text"
                  defaultValue={item?.image_url}
                  loadInitialData={loadInitialData}
                  id={item?.id}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Alert severity="warning">No Banners Found !!</Alert>
          </Grid>
        )}
      </Grid>
      <CreateEditDialog />
    </>
  );
}

export default BottomBanner;
