import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextareaAutosize,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  colors,
} from "@mui/material";
import toast from "react-hot-toast";
import { showModal } from "../../../redux/counterSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  MessageOutlined,
  SignalWifiStatusbarNullRounded,
} from "@mui/icons-material";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { orange } from "@mui/material/colors";
import { Link } from "react-router-dom";

const DispatchTable = ({
  pushToDispatch,
  getDispatchOrderId,
  dispatchImagesList,
  setLoader,
  getDispatchImagesById,
  getOrderDetails,
  locationDetailsId,
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedDispatchPrepare, setSelectedDispatchPrepare] = useState(null);
  const [open, setOpen] = useState(false);

  const openPopup = (
    dispatch_prepare_image_id,
    dispatch_order_id,
    comments
  ) => {
    setOpen(true);
    setSelectedDispatchPrepare({
      dispatch_prepare_image_id,
      dispatch_order_id,
      comments,
    });
  };

  const closePopup = () => {
    setOpen(false);
    setSelectedDispatchPrepare(null);
  };

  const disApproveImage = async (
    dispatch_prepare_image_id,
    dispatch_order_id,
    comment
  ) => {
    if (comment !== "" || comment !== null) {
      const param = {
        dispatch_prepare_image_id: dispatch_prepare_image_id,
        dispatch_order_id: dispatch_order_id,
        comments: comment,
        location_id: locationDetailsId,
      };
      try {
        setLoader(true);
        const response = await axios.post("/disApproveImage", param);
        if (response?.data.error === false) {
          toast.success(response?.data.message);
          closePopup();
          getDispatchImagesById(dispatch_order_id);
        } else {
          toast.warning(response?.data.message);
        }
      } catch (error) {
        dispatch(
          showModal({
            msg: error?.message,
            error: true,
          })
        );
      } finally {
        setLoader(false);
      }
    }
  };
  const approveImage = async (
    dispatch_prepare_image_id = "",
    dispatch_order_id = ""
  ) => {
    const param = {
      dispatch_prepare_image_id: dispatch_prepare_image_id,
      dispatch_order_id: dispatch_order_id,
      location_id: locationDetailsId,
    };
    try {
      setLoader(true);

      const response = await axios.post("/approveImage", param);
      if (response?.data.error === false) {
        toast.success(response?.data.message);
        getOrderDetails();
      } else {
        toast.warning(response?.data.message);
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const RejectPopup = React.memo(() => {
    const [rejectionComment, setRejectionComment] = useState("");
    return (
      <Dialog open={open} onClose={closePopup} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">Reject Reason</DialogTitle>

        <DialogContent>
          <textarea
            aria-label="rejection comment"
            placeholder="Rejection comment"
            value={rejectionComment || selectedDispatchPrepare?.comments}
            style={{ width: "100%", height: "100px" }}
            onChange={(e) => setRejectionComment(e.target.value)}
            rowsMin={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup} variant="contained" color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              !selectedDispatchPrepare?.dispatch_prepare_image_id ||
              !selectedDispatchPrepare?.dispatch_order_id ||
              rejectionComment.length < 5
            }
            color="error"
            variant="contained"
            onClick={() =>
              disApproveImage(
                selectedDispatchPrepare?.dispatch_prepare_image_id,
                selectedDispatchPrepare?.dispatch_order_id,
                rejectionComment
              )
            }
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
  return (
    <div>
      <h5>Processing Team Details</h5>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Location Name</TableCell>
              <TableCell align="center">Address</TableCell>
              {getDispatchOrderId[0]?.scheduled_date !==
                getDispatchOrderId[0]?.created_at && (
                <TableCell align="center">Scheduled At</TableCell>
              )}
              <TableCell align="center">Processing Acceptance</TableCell>
              <TableCell align="center">Accepted At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getDispatchOrderId?.map((dispatch, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  <Link
                    to={`/view-dispatch-order/${dispatch?.dispatch_order_id}`}
                    replace
                  >
                    {dispatch?.location?.location_name}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {dispatch?.location?.address}
                </TableCell>
                {dispatch?.scheduled_date !== dispatch?.created_at && (
                  <TableCell align="center">
                    {dispatch?.scheduled_date}
                  </TableCell>
                )}
                <TableCell align="center">
                  {dispatch?.dispatch_order_status === 1 ? (
                    <Chip label="Accepted" color="success" />
                  ) : (
                    <Chip
                      label="Pending"
                      sx={{ background: "orange", color: "white" }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {dispatch?.dispatch_accepted_at ?? "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dispatchImagesList?.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Approve / Reject</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dispatchImagesList.map((cashier, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <PhotoProvider>
                      <PhotoView key={index} src={cashier?.image}>
                        <img
                          style={{
                            width: "100px",
                            cursor: "pointer",
                            borderRadius: "12px",
                          }}
                          src={cashier?.image}
                          alt={`Image ${index + 1}`}
                        />
                      </PhotoView>
                    </PhotoProvider>
                  </TableCell>
                  <TableCell align="center">
                    {cashier?.approve_status === null ? (
                      <span className="text-warning status_icon m-1">
                        <i
                          className="fa fa-frown-o mr-2 mobile-icon"
                          aria-hidden="true"
                        ></i>
                        <span className="window-text font-weight-bold">
                          Not yet Approved
                        </span>
                      </span>
                    ) : cashier?.approve_status === 1 ? (
                      <span className="text-success status_icon m-1">
                        <i
                          className="fa fa-smile-o mr-2 mobile-icon"
                          aria-hidden="true"
                        ></i>
                        <span className="window-text font-weight-bold">
                          Approved
                        </span>
                      </span>
                    ) : (
                      <span className="text-danger status_icon m-1">
                        <i
                          className="fa fa-frown-o mr-2 mobile-icon"
                          aria-hidden="true"
                        ></i>
                        <span className="window-text">Rejected</span>
                        <MessageOutlined
                          color="primary"
                          size="small"
                          style={{ cursor: "pointer", marginLeft: 8 }}
                          onClick={() =>
                            openPopup(
                              cashier?.dispatch_prepare_image_id,
                              cashier?.dispatch_order_id,
                              cashier?.comments
                            )
                          }
                        />
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() =>
                        approveImage(
                          cashier?.dispatch_prepare_image_id,
                          cashier?.dispatch_order_id
                        )
                      }
                      disabled={
                        cashier?.approve_status === 1 ||
                        cashier?.approve_status === 0
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      size="small"
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="error"
                      onClick={() =>
                        openPopup(
                          cashier?.dispatch_prepare_image_id,
                          cashier?.dispatch_order_id
                        )
                      }
                      disabled={
                        cashier?.approve_status === 1 ||
                        cashier?.approve_status === 0
                      }
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Rejection comment modal */}
      <RejectPopup selectedDispatchPrepare />
    </div>
  );
};

export default DispatchTable;
