import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  colors,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Link } from "react-router-dom";

const DriverDetailsTable = ({ getDriverOrderId }) => {
  const renderDriverAcceptanceChip = (item) => {
    if (item?.driver_acceptance === 1 && item?.picked_up === 0) {
      return <Chip label="Accepted" color="success" />;
    } else if (item?.driver_acceptance === 1 && item?.picked_up === 1) {
      return (
        <Chip
          label="On the way"
          sx={{ background: "orange", color: "white" }}
        />
      );
    } else {
      return <Chip label="Delivered" color="secondary" />;
    }
  };

  return (
    <div className="my-3">
      <h5 className="text-primary">Delivery Team Details</h5>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="driver details table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Region</TableCell>
              <TableCell align="center">Driver Name</TableCell>
              <TableCell align="center">Driver Mobile Number</TableCell>
              <TableCell align="center">Acceptance Status</TableCell>
              {getDriverOrderId[0]?.driver_uploaded_image && (
                <>
                  <TableCell align="center">Delivered Image</TableCell>
                  <TableCell align="center">Delivery Status</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getDriverOrderId.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item?.driver?.city?.name}
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={`/view-driver-order/${item?.driver?.driver_id}/${item?.order_id}`}
                      replace
                    >
                      {item?.driver?.driver_name}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {item?.driver?.driver_mobile}
                  </TableCell>
                  <TableCell align="center">
                    {/* {renderDriverAcceptanceChip(item)} */}

                    {item?.driver_acceptance === 1 ? (
                      <Chip label="Accepted" color="success" />
                    ) : (
                      <Chip
                        label="Pending"
                        sx={{ background: "orange", color: "white" }}
                      />
                    )}
                  </TableCell>
                  {item?.driver_uploaded_image && (
                    <>
                      <TableCell align="center">
                        <PhotoProvider>
                          <PhotoView
                            key={index}
                            src={item?.driver_uploaded_image}
                          >
                            <img
                              style={{
                                cursor: "pointer",
                                width: "100px",
                                borderRadius: "8px",
                              }}
                              src={item?.driver_uploaded_image}
                              alt=""
                              className="product-img"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </TableCell>
                      <TableCell align="center">
                        <span className="text-info status_icon m-1">
                          <i
                            className="fa fa-smile-o mr-2 mobile-icon"
                            aria-hidden="true"
                          ></i>
                          <span className="window-text">Delivered</span>
                        </span>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DriverDetailsTable;
