import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import MUIDataTable from "mui-datatables";
import Loader from "../../../components/loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ArrowCircleRight, ArrowForwardOutlined } from "@mui/icons-material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import CircularWithValueLabel from "../../../components/form-components/CircularProgressWithLabel";

function HomeRegionSelection() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/getAllActiveRegionListWithCounts")
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "region_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "city.name",
      label: "City",
    },
    {
      name: "Banner",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.banner;
          let status;
          let percent;
          if (value >= 4) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 2 && value <= 3) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "category",
      label: "Category",

      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.category;
          let status;
          let percent;
          if (value >= 4) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 2 && value <= 3) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "best_seller",
      label: "Best Seller",

      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.best_seller;
          let status;
          let percent;
          if (value >= 6) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 3 && value <= 5) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "new_arrivals",
      label: "New Arrivals",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.latest_arrivals;
          let status;
          let percent;
          if (value >= 6) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 3 && value <= 5) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "flavour",
      label: "Flavour",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.flavour;
          let status;
          let percent;
          if (value >= 5) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 3 && value <= 4) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "sub_category",
      label: "Sub Category",

      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.sub_category;
          let status;
          let percent;
          if (value == 4) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 2 && value < 4) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "Custom",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const value = formData[dataIndex]?.custom;
          let status;
          let percent;
          if (value == 4) {
            status = "GOOD";
            percent = 100;
          } else if (value >= 2 && value < 4) {
            status = "WARNING";
            percent = 60;
          } else {
            status = "DANGER";
            percent = 30;
          }
          return <CircularWithValueLabel value={percent} status={status} />;
        },
      },
    },
    {
      name: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const region = formData[dataIndex]?.region_id;
          const cityName = formData[dataIndex]?.city?.name;

          return (
            <div className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => navigate(`/home-section/${region}/${cityName}`)}
              >
                <ArrowForwardOutlined fontSize="medium" color="primary" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    print: false,
    enableNestedDataAccess: ".",
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h5 className="text-dark">Home Section</h5>
          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
          />
        </>
      )}
    </>
  );
}

export default HomeRegionSelection;
