import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { showModal } from "../../app/store/slice/counterSlice";
import ImageUpload from "../../components/form-components/ImageUpload";
import AddIcon from "@mui/icons-material/Add";
import { Alert } from "@mui/material";
import { FormInputText } from "../../components/form-components/FormInputText";

function BannerUpload({ regionId }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [routeItems, setRouteItems] = useState({});

  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);

  const methods = useForm();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const onSubmit = (data) => save(data);

  const save = async (data) => {
    setLoader(true);
    try {
      const payload = { ...data, region_id: regionId };
      const response = await axios.post("/banner", payload);
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setOpen(false);
      setLoader(false);
    }
  };

  const loadInitialData = () => {
    setLoader(true); // Start the loader before making the requests

    axios
      .all([
        axios.get("/bannerByRegion/" + regionId),
        axios.get("/getAllRouteItems"), // Add your second API endpoint here
      ])
      .then(
        axios.spread((bannerResponse, routeResponse) => {
          // Handle the response for each API call
          setFormData(bannerResponse?.data?.data);
          setRouteItems(routeResponse?.data?.data);
          setLoader(false); // Stop the loader after the data is loaded
        })
      )
      .catch(function (err) {
        toast.error(err?.message);
        setLoader(false); // Stop the loader even if there is an error
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle className="text-primary">New Banner</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <FormInputText
                name="banner_name"
                control={control}
                label="Banner Name"
                errors={errors}
                mandatory="true"
                // defaultValue={selectedRow?.product_name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
  return loader ? (
    <Loader />
  ) : (
    <>
      <Grid item xs={12}>
        <ul
          className="w-100 d-inline-flex justify-content-around"
          style={{ color: "red" }}
        >
          {formData?.length <= 5 && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpen(true)}
              icon={<AddIcon />}
            >
              New Banner
            </Button>
          )}

          <li>Please upload only jpg, jpeg, png.</li>
          <li>File size shall not exceed 300kb</li>
          <li>Dimension : 2:1 ratio</li>
        </ul>
      </Grid>
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 5 }}
        style={{ display: "flex" }}
      >
        {formData?.length > 0 ? (
          formData?.map((banner, index) => {
            return (
              <Grid item xs={12} md={4} lg={4} xl={4} key={index}>
                <ImageUpload
                  status={banner?.active_status}
                  name={banner?.id?.toString()}
                  control={control}
                  label={banner?.banner_name}
                  // setValue={setValue}
                  type="text"
                  defaultValue={banner?.banner_image}
                  loadInitialData={loadInitialData}
                  regionId={regionId}
                  routeList={routeItems}
                  routeValue={{
                    route_type: banner?.route_type,
                    route_item: banner?.route_item,
                  }}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Alert severity="warning">No Banners Found !!</Alert>
          </Grid>
        )}
      </Grid>
      <CreateEditDialog />
    </>
  );
}

export default BannerUpload;
