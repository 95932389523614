import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { MuiColorInput } from "mui-color-input";

export const FormColorPicker = ({
  name,
  control,
  label,
  type,
  options,
  errors,
  defaultValue,
  mandatory,
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControl
            fullWidth
            error={errors?.[name]?.message ? true : false}
            margin="normal"
          >
            <MuiColorInput
              // {...field}
              format="hex"
              value={value}
              onChange={onChange}
            />
          </FormControl>
        );
      }}
      control={control}
      name={name}
    />
  );
};
