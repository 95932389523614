import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";
import { reset } from "./app/store/slice/counterSlice";
import { toast } from "react-hot-toast";
import { persistor, store } from "./redux/store";

axios.interceptors.request.use(
  (config) => {
    // Modify config or add headers here
    // For example, adding an authorization header
    const token = JSON.parse(sessionStorage.getItem("userData"))?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // Modify response data or handle common errors here
    // For example, handling unauthorized or expired tokens
    if (response.status === 401) {
      logoutHandler(response?.message);
      // Handle unauthorized scenario
      // Redirect to the login page, show an error message, etc.
    }
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);
const logoutHandler = async () => {
  signOut(auth)
    .then(() => {
      sessionStorage.clear();
      localStorage.clear();
      useDispatch(reset());
      toast.success("Logged out successfully");
      useNavigate("/login");
    })
    .catch((error) => {});
};

// let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  //  </React.StrictMode>
);
