import { useState, useEffect } from 'react';

function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? item : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      sessionStorage.setItem(key, value);
    } catch (error) {
      console.error(error);
    }
  };

  // Listen for sessionStorage changes and update state
  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = sessionStorage.getItem(key);
      if (newValue !== storedValue) {
        setStoredValue(newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, storedValue]);

  return [storedValue, setValue];
}

export default useSessionStorage;
