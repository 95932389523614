import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  EmojiEmotions,
  EmojiEmotionsOutlined,
  MoodBad,
  SentimentDissatisfied,
  SentimentDissatisfiedOutlined,
  SentimentNeutral,
} from "@mui/icons-material";

function CircularProgressWithLabel(props) {
  let status;
  if (props?.status === "GOOD") {
    status = "success.main";
    return <EmojiEmotions sx={{ color: status }} />;
  } else if (props?.status === "WARNING") {
    status = "warning.main";
    return <SentimentNeutral sx={{ color: status }} />;
  } else if (props?.status === "DANGER") {
    status = "error.main";
    return <MoodBad sx={{ color: status }} />;
  }
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel({ value, status }) {
  return <CircularProgressWithLabel value={value} status={status} />;
}
