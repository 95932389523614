import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
import { useDispatch } from "react-redux";
import { showModal } from "../../app/store/slice/counterSlice";
import ImageUpload from "../../components/form-components/ImageUpload";
import BasicBreadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import GeneralPreviewBannerUpload from "../../components/form-components/GeneralPreviewBannerUpload";

function PreviewBanner({ regionId }) {
  const dispatch = useDispatch();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [showBtn, setShowBtn] = useState(true);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const methods = useForm();
  const { control, setValue } = methods;

  const loadInitialData = () => {
    axios
      .get("/getHomeGeneralBanner/" + regionId)
      .then(function (response) {
        setFormData(response?.data?.data);

        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  return loader ? (
    <Loader />
  ) : (
    <>
      <h6 className="text-dark mt-2 screen-heading">Banner Upload</h6>
      <Grid
        container
        spacing={2}
        columnSpacing={{ xs: 5 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={6}>
          <GeneralPreviewBannerUpload
            status={formData?.active_status}
            name={"home_general_banner"}
            control={control}
            label={"Preview Banner"}
            setValue={setValue}
            type="text"
            defaultValue={formData?.image_url}
            loadInitialData={loadInitialData}
            regionId={regionId}
          />
        </Grid>
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 3,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <ul className=" justify-content-around image-upload-instructions">
              <li>Please upload only jpg, jpeg, png.</li>
              <li>File size shall not exceed 300kb</li>
              <li>Dimension : 2:1 ratio</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default PreviewBanner;
