import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/loader/Loader";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, orange } from "@mui/material/colors";
import { FormInputText } from "../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import { discountSchema, subCategorySchema } from "../../app/schema";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import { showModal, storeSubCategoryList } from "../../redux/counterSlice";
import GeneralImageUpload from "../../components/form-components/GeneralImageUpload";
import { DatePicker } from "@mui/x-date-pickers";
import { FormInputDate } from "../../components/form-components/FormInputDate";
import { format, parse } from "date-fns";

function Discount() {
  const dispatch = useDispatch();
  const { taxList, categoryList } = useSelector((state) => state.counter);

  const methods = useForm({
    resolver: yupResolver(discountSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/getAllDiscountList")
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "discount_name",
      label: "Discount Name",
    },

    {
      name: "discount_percentage",
      label: "Percentage",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{formData[dataIndex]?.discount_percentage}%</p>;
        },
      },
    },
    {
      name: "fromDate",
      label: "From Date",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <p>{format(new Date(formData[dataIndex]?.fromDate), "dd-MMM-yyy")}</p>
        ),
      },
    },

    {
      name: "toDate",
      label: "To Date",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <p>{format(new Date(formData[dataIndex]?.toDate), "dd-MMM-yyy")}</p>
        ),
      },
    },

    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                setSelectedRow(() => ({
                  ...formData[dataIndex],
                  action: () =>
                    toggleStatus(
                      formData[dataIndex]?.discount_id,
                      formData[dataIndex]?.active_status
                    ),
                  confirmLabel: status === 1 ? "Deactivate" : "Activate",
                }));
                setConfirmOpen(true);
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Edit",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({
                    ...formData[dataIndex],
                    action: () =>
                      deleteClickHandler(formData[dataIndex]?.discount_id),
                    confirmLabel: "Delete",
                  }));
                  setConfirmOpen(true);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  color="error"
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "red" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };

  const toggleStatus = (id, status) => {
    setLoader(true);
    const toggleDiscountApi =
      status == 0 ? "/activateDiscount/" : "/deActivateDiscount/";
    axios
      .get(toggleDiscountApi + id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        setConfirmOpen(false);
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      const payload = {
        discount_name: data?.discount_name,
        discount_percentage: data?.discount_percentage,
        fromDate: format(new Date(data?.fromDate), "yyyy-MM-dd"),
        toDate: format(new Date(data?.toDate), "yyyy-MM-dd"),
      };

      if (selectedRow?.discount_id) {
        response = await axios.post("/updateDiscount", {
          discount_id: selectedRow?.discount_id,
          ...payload,
        });
      } else {
        response = await axios.post("/createDiscount", payload);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow(() => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
        setOpen(false);
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
      setOpen(false);
    } finally {
      setLoader(false);
    }
  };
  const deleteClickHandler = async (id) => {
    setLoader(true);
    try {
      let response;
      if (id) {
        response = await axios.get("/deleteDiscount/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow(() => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
      setConfirmOpen(false);
    }
  };
  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Discount" : "New Discount"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={6}>
              <FormInputText
                name="discount_name"
                control={control}
                label="Discount Name"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.discount_name}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`discount_percentage`}
                control={control}
                defaultValue={selectedRow?.discount_percentage || ""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    type="number"
                    id="outlined-basic"
                    label="Percentage"
                    variant="outlined"
                    onChange={onChange}
                    value={value || ""}
                    error={error?.message ? true : false}
                    margin="normal"
                    defaultValue={selectedRow?.discount_percentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputDate
                name="fromDate"
                control={control}
                label="From Date"
                type="date"
                errors={errors}
                defaultValue={selectedRow?.fromDate}
                mandatory="true"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputDate
                name="toDate"
                control={control}
                label="To Date"
                type="date"
                errors={errors}
                defaultValue={selectedRow?.toDate}
                mandatory="true"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    viewColumns: true,
    print: false,
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Discount</h4>
          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                icon={<AddIcon />}
              >
                New Discount
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Discount;
