// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// FIREBASE CREDENTIALS
const firebaseConfig = {
  apiKey: "AIzaSyDGSuXvhZQnpRnAau7Dwqbg8U_AE-cfFj8",
  authDomain: "backtopedals-17bec.firebaseapp.com",
  projectId: "backtopedals-17bec",
  storageBucket: "backtopedals-17bec.appspot.com",
  messagingSenderId: "28509139396",
  appId: "1:28509139396:web:b24d0995f7898c1d45b491",
  measurementId: "G-PHP0KDKJRQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
