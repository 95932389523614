import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/loader/Loader";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, red } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import { citySchema } from "../../../app/schema";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import { TableRow } from "@mui/material";
import { AccountBalanceOutlined } from "@mui/icons-material";

function City() {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(citySchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [state, setState] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({});
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const loadInitialData = async () => {
    setLoader(true);
    axios
      .all([axios.get("/getAllRegionList"), axios.get("/getStates")])
      .then(function (response) {
        setFormData(response[0]?.data?.data);
        setState(response[1]?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);
  const deleteClickHandler = async (id) => {
    try {
      let response;
      if (id) {
        response = await axios.get("/deleteRegion/" + id);
        if (!response?.data?.error) {
          toast.success(response?.data?.message);
          loadInitialData();
          setOpen(false);
          setSelectedRow((prev) => null);
          reset();
        } else {
          let errDesc = "";
          if (response?.data?.data) {
            Object.values(response?.data?.data).map((error, index) => {
              errDesc =
                index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
            });
          } else {
            errDesc = response?.data?.message;
          }

          dispatch(
            showModal({
              msg: errDesc,
              error: response?.data?.error,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
      setOpen(false);
      setConfirmOpen(false);
    }
  };

  const columns = [
    {
      name: "region_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "state.name",
      label: "State",
    },

    {
      name: "city.name",
      label: "City",
    },

    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                dispatch(
                  showConfirmModal({
                    msg: status === 1 ? "deactivate" : "activate",
                    action: () => toggleStatus(formData[dataIndex]?.region_id),
                  })
                );
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          return (
            <div className="actionBtns">
              <Button
                aria-label="edit"
                onClick={() => {
                  setOpenPaymentDialog(true);
                  setPaymentDetails(formData[dataIndex]?.payment_account);
                }}
              >
                <AccountBalanceOutlined
                  fontSize="medium"
                  sx={{ color: "orange" }}
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="delete"
                onClick={() => {
                  dispatch(
                    showConfirmModal({
                      msg: "delete",
                      action: () =>
                        deleteClickHandler(formData[dataIndex]?.region_id),
                    })
                  );
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    color: status === 1 ? grey[600] : red[500],
                  }}
                  fontSize="medium"
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    const payload = {
      ...data,
      country_id: 101,
      // address: "dummy address",
    };
    saveForm(payload);
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/regionSwitch/" + id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      if (selectedRow?.region_id) {
        response = await axios.post(
          "/updateRegion",
          { ...data, region_id: selectedRow?.region_id },
          data
        );
      } else {
        response = await axios.post("/createRegion", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow(() => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const CreateEditDialog = React.memo(() => {
    const [city, setCity] = useState([]);
    const loadCity = (value) => {
      if (value > 0) {
        axios
          .get(`/getCityOfState/${value}`)
          .then(function (response) {
            setCity(response?.data?.data);
          })
          .catch(function (err) {
            toast.error(err?.message);
          });
      }
    };
    useEffect(() => loadCity(selectedRow?.state_id), []);
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit City" : "New City"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <Controller
                defaultValue={selectedRow?.state_id}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl
                      margin="normal"
                      className="w-100"
                      error={errors?.["state_id"]?.message ? true : false}
                    >
                      <InputLabel id="status_dropdown_label">State</InputLabel>
                      <Select
                        labelId="state_dropdown_label"
                        id={`state_dropdown_select`}
                        value={value}
                        label="State"
                        onChange={(e) => {
                          loadCity(e?.target?.value);
                          onChange(e?.target?.value);
                        }}
                      >
                        <MenuItem value={0}>--Select State--</MenuItem>
                        {state?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
                control={control}
                name={"state_id"}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                defaultValue={selectedRow?.city_id || ""}
                render={({ field: { onChange } }) => {
                  return (
                    city.length > 0 && (
                      <Autocomplete
                        sx={{ marginTop: "16px" }}
                        control
                        onChange={(_, data) => {
                          onChange(data?.value);
                          return data;
                        }}
                        id="tags-standard"
                        options={[
                          { value: 0, label: "Select" },
                          ...city?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          })),
                        ]}
                        getOptionLabel={(option) => option?.label}
                        defaultValue={{
                          value: selectedRow?.city?.id || 0,
                          label: selectedRow?.city?.name || "Select",
                        }}
                        // defaultValue={selectedRow?.city_id}
                        disableCloseOnSelect
                        renderOption={(props, option) => (
                          <li {...props}>{option.label}</li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"City"}
                            placeholder={"City"}
                            error={errors?.["city_id"]?.message ? true : false}
                          />
                        )}
                      />
                    )
                  );
                }}
                control={control}
                name={"city_id"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
  const PaymentDetailsDialog = () => {
    return (
      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Payment Account</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell sx={{ fontWeight: "medium" }}>
                    {paymentDetails?.merchant_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell sx={{ fontWeight: "medium" }}>
                    {paymentDetails?.business_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Login Email</TableCell>
                  <TableCell sx={{ fontWeight: "medium" }}>
                    {paymentDetails?.login_email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone Number</TableCell>
                  <TableCell sx={{ fontWeight: "medium" }}>
                    {paymentDetails?.mobile_number}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell sx={{ fontWeight: "medium" }}>
                    {paymentDetails?.business_address}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPaymentDialog(false)}
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
    enableNestedDataAccess: ".",
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">City</h4>

          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                icon={<AddIcon />}
              >
                New City
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <PaymentDetailsDialog />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default City;
