import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taxList: [],
  categoryList: [],
  subCategoryList: [],
  flavourList: [],
  unitList: [],
  weightList: [],
  regionList: [],
  modal: { flag: false, message: "", error: false },
  confirmModal: { flag: false, message: "", action: () => {} },
  userData: {},
  notificationList: [],
  pageSettings: {
    order: {
      pageSize: 10,
      pageNo: 0,
    },
    product: {
      pageSize: 10,
      pageNo: 0,
    },
    shop: {
      pageSize: 10,
      pageNo: 0,
    },
  },
};
export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    handlePageSettings: (state, action) => {
      state.pageSettings = {
        ...state.pageSettings,
        [action.payload.screen]: {
          pageSize: action.payload.pageSize,
          pageNo: action.payload.pageNo,
        },
      };
    },
    handleProductSearch: (state, action) => {
      state.settings = {
        product: action.payload,
      };
    },
    storeTaxList: (state, action) => {
      state.taxList = action.payload;
    },
    storeCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    storeSubCategoryList: (state, action) => {
      state.subCategoryList = action.payload;
    },
    storeFlavourList: (state, action) => {
      state.flavourList = action.payload;
    },
    storeUnitList: (state, action) => {
      state.unitList = action.payload;
    },
    storeWeightList: (state, action) => {
      state.weightList = action.payload;
    },
    storeRegionList: (state, action) => {
      state.regionList = action.payload;
    },
    addNotificationList: (state, action) => {
      const newNotification = action.payload;

      // Ensure state.notificationList is an array
      if (!Array.isArray(state.notificationList)) {
        state.notificationList = [];
      }

      // Add the new notification to the notificationList array
      state.notificationList.push(newNotification);
    },
    removeNotificationItem: (state, action) => {
      const itemIdToRemove = action.payload;
      state.notificationList = state.notificationList?.filter(
        (item) => item.id === itemIdToRemove
      );
    },
    userDataReducer: (state, action) => {
      state.userData = action.payload;
    },
    showModal: (state, action) => {
      state.modal = {
        flag: true,
        message: action.payload.msg,
        error: action.payload.error,
      };
    },
    hideModal: (state, action) => {
      state.modal = {
        flag: false,
        message: action.payload.msg,
        error: action.payload.error,
      };
    },
    showConfirmModal: (state, action) => {
      state.confirmModal = {
        flag: true,
        message: action.payload.msg,
        action: action?.payload?.action,
      };
    },
    hideConfirmModal: (state, action) => {
      state.confirmModal = {
        flag: false,
        message: action.payload.msg,
        action: () => {},
      };
    },
    reset: () => initialState,
  },
});

export const {
  storeTaxList,
  storeCategoryList,
  storeSubCategoryList,
  showModal,
  hideModal,
  userDataReducer,
  storeFlavourList,
  storeUnitList,
  storeWeightList,
  storeRegionList,
  showConfirmModal,
  hideConfirmModal,
  addNotificationList,
  removeNotificationItem,
  handleProductSearch,
  handlePageSettings,
} = counterSlice.actions;

export default counterSlice.reducer;
