import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Grid, Paper, Avatar, TextField, Typography, Box } from "@mui/material";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import ShowSnackbar from "../../components/snackbar/snackbar";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { LOGO } from "../../app/utlils";
import { showModal, userDataReducer } from "../../redux/counterSlice";

const schema = yup.object().shape({
  user_name: yup.string().required(),
  password: yup.string().max(32).required(),
});

function LoginScreen() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const login = (data) => {
    try {
      axios
        .post("/loginUser", data)
        .then(function (response) {
          const res = response?.data;
          if (!res?.error) {
            let storageData = { token: res?.data, loggedIn: true };
            sessionStorage.setItem("userData", JSON.stringify(storageData));
            dispatch(userDataReducer(storageData));
            navigate("/dashboard");
            toast.success("Logged in successfully");
          } else {
            dispatch(
              showModal({
                msg: res?.message,
                error: true,
              })
            );
          }
        })
        .catch(function (error) {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    } catch (err) {
      toast.error(err?.message);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(login);
    }
  };

  const paperStyle = {
    padding: 20,
    maxWidth: "400px",
    margin: "10%",
  };
  const btnstyle = { margin: "8px 0", background: "#E63D24" };
  return (
    <Box
      className="login-page"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={10} style={paperStyle} className="bg-light">
        <form onSubmit={handleSubmit(login)}>
          <Grid container>
            <Grid item align="center" m={"auto"}>
              <img src={require("../../assets/logo.png")} alt="" width={200} />
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormInputText
                name="user_name"
                control={control}
                label="User Name"
                errors={errors}
                type="text"
                autofocus={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="password"
                control={control}
                label="Password"
                errors={errors}
                type="password"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            // onClick={handleSubmit(login)}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Box>
  );
}

export default LoginScreen;
