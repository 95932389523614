import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Loader from "../../../components/loader/Loader";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { hideConfirmModal, showModal } from "../../../redux/counterSlice";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DispatchImageUpload from "../../../components/form-components/DispatchImageUpload";
import { MessageOutlined } from "@mui/icons-material";

function ViewDispatchOrders() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPrepareImage, setSelectedPrepareImage] = useState(null);
  const [commentPopup, setCommentPopup] = useState({
    flag: false,
    comments: "",
  });

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [dispatchOrder, setDispatchOrder] = useState(null);
  const [orderId, setOrderId] = useState();
  const [dispatchPrepareImages, setDispatchPrepareImages] = useState([]);

  const loadInitialData = () => {
    const url = "/getDispatchOrderByDispatchId/" + id;
    setLoader(true);
    handleClose();
    axios
      .get(url)
      .then(function (response) {
        setDispatchOrder(response?.data?.data);
        setOrderList(response?.data?.data?.[0]?.order?.ordered_products);
        setAddonList(response?.data?.data?.[0]?.order?.ordered_addons);
        setOrderId(response?.data?.data?.[0]?.order_id);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const columns = [
    {
      name: "dispatch_prepare_image_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = dispatchPrepareImages[dataIndex]?.image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "product_name",
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <p>{dispatchPrepareImages[dataIndex]?.product?.product_name}</p>
          );
        },
      },
    },

    {
      name: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          let status = null;
          let chipColor = null;
          const approve_status =
            dispatchPrepareImages[dataIndex]?.approve_status;
          if (approve_status == null) {
            status = "Pending";
            chipColor = "bg-warning";
          } else if (approve_status == 1) {
            status = "Approved";
            chipColor = "bg-success";
          } else if (approve_status == 0) {
            status = "Rejected";
            chipColor = "bg-danger";
          }
          return (
            <Chip
              className={chipColor}
              label={status}
              color="primary"
              size="small"
            />
          );
        },
      },
    },
    {
      name: "Comments",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const comments = dispatchPrepareImages[dataIndex]?.comments;

          return comments ? (
            <MessageOutlined
              color="primary"
              size="small"
              style={{ cursor: "pointer", marginLeft: 8 }}
              onClick={() => setCommentPopup({ flag: true, comments })}
            />
          ) : (
            "-"
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
  };

  const getDispatchImages = () => {
    const url = "/getDispatchImagesById/" + id;
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setDispatchPrepareImages(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };
  const acceptOrders = async () => {
    try {
      const params = {
        dispatch_order_id: id,
      };
      const response = await axios.post("/acceptDispatchOrders", params);

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        getDispatchImages();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    }
  };
  useEffect(() => {
    loadInitialData();
    getDispatchImages();
  }, []);

  function onlyStartTime(slot) {
    let splitArray = slot?.split("to");
    return splitArray?.[0];
  }
  const imageUploadHandler = (orderRow) => {
    setOpenImage(true);
    setSelectedRow((prev) => ({
      ...orderRow,
    }));
    console.log("row", {
      ...orderRow,
    });
  };

  const Info = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle className="text-primary">Cake Details</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item xs={12}>
              <div className="d-flex flex-column">
                <Typography variant="h5" className="mb-0 mobile-view-prod-name">
                  {selectedRow?.product_details?.[0]?.product_name}
                </Typography>

                <Typography variant="subtitle1">
                  Weight:
                  {`${selectedRow?.variation?.weight?.[0]?.weight_name} ${selectedRow?.variation?.weight?.[0]?.unit.unit_name}`}
                </Typography>
                <Typography variant="subtitle1">
                  Quantity: {selectedRow?.product_quantity}
                </Typography>
                {selectedRow?.egg_or_eggless === 0 && (
                  <Typography variant="subtitle1">
                    Cake Type: <span className="text-danger">Egg</span>
                  </Typography>
                )}
                {selectedRow?.egg_or_eggless === 1 && (
                  <Typography variant="subtitle1">
                    Cake Type: <span className="text-success">Eggless</span>
                  </Typography>
                )}
                {selectedRow?.flavour?.flavour_name !== "Default" && (
                  <Typography variant="subtitle1">
                    Flavour: {selectedRow?.flavour?.flavour_name}
                  </Typography>
                )}
                {selectedRow?.message_on_cake !== "" && (
                  <Typography variant="subtitle1">
                    Message On Cake: {selectedRow?.message_on_cake}
                  </Typography>
                )}
                <Typography variant="subtitle1">
                  Slot: {onlyStartTime(dispatchOrder?.[0]?.order.slot_timing)}
                </Typography>
                <Typography variant="subtitle1">
                  Delivery Date: {dispatchOrder?.[0]?.order.expected_delivery}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Update Image</DialogTitle>
        <Divider variant="middle" className="bg-primary" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <DispatchImageUpload
                loadInitialData={loadInitialData}
                locationDetailsId={dispatchOrder?.[0]?.location_details_id}
                selectedRow={selectedRow}
                getDispatchImages={getDispatchImages}
                dispatchOrderId={id}
                defaultValue={selectedRow?.image}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CommentPopup = () => (
    <Dialog
      fullWidth
      open={commentPopup?.flag}
      onClose={() => setCommentPopup({ flag: false, comments: "" })}
    >
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>{commentPopup?.comments}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setCommentPopup({ flag: false, comments: "" })}
          variant="contained"
          color="error"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex  justify-content-between">
            <div className="d-flex">
              <ArrowBackIcon
                className="text-danger"
                sx={{ marginRight: "1rem", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h5 className="text-dark">Processing Order Details</h5>
            </div>

            {dispatchOrder?.[0]?.dispatch_order_status != 1 && (
              <Button
                variant="contained"
                color="info"
                onClick={() => acceptOrders()}
              >
                Accept Order
              </Button>
            )}
          </div>

          <Grid item xs={12} sx={{ mb: 1 }}>
            <Grid container spacing={2}>
              {orderList.map((order, index) => (
                <Grid item key={index} xs={12} md={6} lg={4} xl={4}>
                  <Card>
                    <CardContent>
                      <div className="py-6 px-6 align-items-center">
                        <div className="row mb-6 justify-content-between">
                          <div className="col-4">
                            {order.product_details[0]?.images.map(
                              (image, k) => (
                                <div key={k} className="w-icon viewIm">
                                  <PhotoProvider>
                                    <PhotoView
                                      key={index}
                                      src={image?.app_product_image}
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          borderRadius: 8,
                                          cursor: "pointer",
                                        }}
                                        src={image?.app_product_image}
                                        alt="user avatar"
                                        className="customer-img"
                                      />
                                    </PhotoView>
                                  </PhotoProvider>
                                </div>
                              )
                            )}
                          </div>
                          <div className="col-8">
                            <div className="media-body ml-3 border-left-xs border-light-3">
                              <div className="d-flex flex-column order-details-box">
                                <Typography
                                  variant="h6"
                                  className="mb-0 mobile-view-prod-name"
                                >
                                  {order.product_details[0]?.product_name}
                                </Typography>

                                <Typography variant="subtitle1">
                                  Weight:{" "}
                                  {order.variation.weight[0].weight_name}{" "}
                                  {order.variation.weight[0].unit.unit_name}
                                </Typography>
                                <Typography variant="subtitle1">
                                  Quantity: {order?.product_quantity}
                                </Typography>
                                {order.egg_or_eggless === 0 && (
                                  <Typography variant="subtitle1">
                                    Cake Type:{" "}
                                    <span className="text-danger">Egg</span>
                                  </Typography>
                                )}
                                {order.egg_or_eggless === 1 && (
                                  <Typography variant="subtitle1">
                                    Cake Type:{" "}
                                    <span className="text-success">
                                      Eggless
                                    </span>
                                  </Typography>
                                )}
                                {order.flavour.flavour_name !== "Default" && (
                                  <Typography variant="subtitle1">
                                    Flavour: {order.flavour.flavour_name}
                                  </Typography>
                                )}
                                {order.message_on_cake !== "" && (
                                  <Typography variant="subtitle1">
                                    Message On Cake: {order.message_on_cake}
                                  </Typography>
                                )}
                                <Typography variant="subtitle1">
                                  Slot:{" "}
                                  {onlyStartTime(
                                    dispatchOrder[0].order.slot_timing
                                  )}
                                </Typography>
                                <Typography variant="subtitle1">
                                  Delivery Date:{" "}
                                  {dispatchOrder[0].order.expected_delivery}
                                </Typography>
                              </div>
                              <hr
                                style={{ marginTop: 0, marginBottom: "1em" }}
                              />
                              {dispatchOrder?.[0]?.dispatch_order_status ===
                                1 && (
                                <Button
                                  variant="contained"
                                  color="info"
                                  onClick={() => imageUploadHandler(order)}
                                  style={{ width: "150px", margin: "auto" }}
                                  className="cusBtn"
                                >
                                  Upload Image
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {addonList.map((addon, index) => (
                <Grid item key={index} xs={12} md={6} lg={4} xl={4}>
                  <Card>
                    <CardContent>
                      <div className="py-6 px-6 align-items-center">
                        <div className="row mb-6 justify-content-between">
                          <div className="col-4">
                            <div key={index} className="w-icon viewIm">
                              <PhotoProvider>
                                <PhotoView key={index} src={addon?.image}>
                                  <img
                                    style={{
                                      width: "100%",
                                      borderRadius: 8,
                                      cursor: "pointer",
                                    }}
                                    src={addon?.image}
                                    alt="user avatar"
                                    className="customer-img"
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="media-body ml-3 border-left-xs border-light-3">
                              <div className="d-flex flex-column order-details-box">
                                <Typography
                                  variant="h6"
                                  className="mb-0 mobile-view-prod-name"
                                >
                                  {addon.product_name}
                                </Typography>
                                <Typography variant="subtitle1">
                                  Quantity: {addon?.quantity}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <MUIDataTable
            data={
              dispatchPrepareImages?.length > 0 ? dispatchPrepareImages : []
            }
            columns={columns}
            options={options}
          />
        </>
      )}
      <Info />
      <UpdateImageDialog />
      <CommentPopup />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewDispatchOrders;
