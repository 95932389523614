import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

export const FormInputDropdown = ({
  name,
  control,
  label,
  type,
  options,
  errors,
  defaultValue,
  mandatory,
}) => {
  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option?.id} value={option?.id}>
          {option?.name}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl
            fullWidth
            error={errors?.[name]?.message ? true : false}
            // sx={{ my: 2 }}
            margin="normal"
          >
            <InputLabel id={`${name}-label`}> {label}</InputLabel>
            <Select
              labelId={`${name}-label`}
              id={`${name}-select`}
              defaultValue={defaultValue}
              control
              label={label}
              onChange={onChange}
            >
              <MenuItem value={""}>--SELECT--</MenuItem>
              {generateSingleOptions()}
            </Select>
          </FormControl>
        );
      }}
      control={control}
      name={name}
    />
  );
};
