import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Autocomplete, Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const FormAutoCompleteGroup = ({
  name,
  control,
  label,
  dataArray,
  defaultValue,
  mandatory,
  errors,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            sx={{ marginTop: "16px" }}
            control
            multiple
            onChange={(_, data) => {
              onChange(data);
              return data;
            }}
            id="tags-standard"
            options={dataArray}
            // isOptionEqualToValue={(option, value) => option?.id == value?.id}
            getOptionLabel={(option) => option?.label}
            defaultValue={defaultValue}
            disableCloseOnSelect
            groupBy={(option) => option.group}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={label}
                error={errors?.[name]?.message ? true : false}
              />
            )}
          />
        );
      }}
    />
  );
};
