import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import { PhotoProvider, PhotoView } from "react-photo-view";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../redux/counterSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate, useParams } from "react-router-dom";
import DriverDetailsTable from "../../order/orderList/DriverDetailsTable";
import { useDispatch } from "react-redux";
import OrderListTable from "../../order/orderList/OrderListTable";
import OrderSummary from "../../order/orderList/OrderSummary";

function ViewDriverOrder() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { driverId, orderId } = useParams();

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPrepareImage, setSelectedPrepareImage] = useState(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [driverOrder, setDriverOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState({});

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;

  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onloadend = () => {
  //     setImage(reader.result);
  //     setLoader(false);
  //   };
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage({ name: file });
      setImageUrl(URL.createObjectURL(file));
    }
  };
  const loadInitialData = () => {
    const url = "/getDriverOrderByOrderId/" + orderId;
    const payload = {
      driverId: driverId,
      orderId: orderId,
      operation: "GET_DRIVER_ORDERS_OPERATION_BY_ID",
    };
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setDriverOrder(response?.data?.data);
        setOrderDetails(response?.data?.data?.[0]?.order);
        setOrderList(response?.data?.data?.[0]?.order?.ordered_products);
        setAddonList(response?.data?.data?.[0]?.order?.ordered_addons);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const acceptOrders = async () => {
    setLoader(true);
    try {
      const params = {
        operation: "DRIVER_ACCEPTANCE_OPERATION",
        orderId: orderId,
      };
      const response = await axios.post("/acceptOrderByDriver", params);

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data)?.map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const pickedupOrder = async () => {
    setLoader(true);
    try {
      const params = {
        operation: "PICK_UP_BY_DRIVER_OPERATION",
        orderId: orderId,
      };
      const response = await axios.post("/orderPickedUpByDriver", params);

      if (!response?.data?.error) {
        dispatch(hideConfirmModal({ msg: "" }));
        toast.success(response?.data?.message);
        loadInitialData();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data)?.map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  const deliverOrder = async () => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("image", selectedImage?.name);
      formData.append("orderId", orderId);
      formData.append("driverId", driverId);
      formData.append("operation", "UPLOAD_IMAGE_AND_DO_DELIVERY");

      const response = await axios.post("/deliverDriverOrder", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!response?.data?.error) {
        dispatch(hideConfirmModal({ msg: "" }));
        toast.success(response?.data?.message);
        navigate(-1);
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data)?.map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <ArrowBackIcon
                className="text-danger"
                sx={{ marginRight: "1rem", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h4 className="text-dark">Order Details</h4>
            </div>
            {driverOrder?.[0]?.driver_acceptance != 1 && !loader && (
              <Button
                variant="contained"
                color="info"
                onClick={() => acceptOrders()}
              >
                Accept Order
              </Button>
            )}
            {driverOrder?.[0]?.driver_acceptance === 1 &&
              driverOrder?.[0]?.picked_up === 0 && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => pickedupOrder()}
                >
                  Order Picked Up
                </Button>
              )}
            {driverOrder[0]?.driver_acceptance == 1 &&
              driverOrder[0]?.picked_up == 1 &&
              !loader && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => setOpenImage(true)}
                >
                  Delivered
                </Button>
              )}
          </div>

          <Grid item xs={12} sx={{ mb: 1 }}>
            <Grid item xs={12}>
              <OrderListTable
                productList={orderDetails?.ordered_products}
                addonList={orderDetails?.ordered_addons}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {driverOrder?.[0]?.driver && (
              <DriverDetailsTable getDriverOrderId={driverOrder} />
            )}
          </Grid>
          <Grid item xs={12}>
            <h5 className="text-primary mt-3">Order Summary</h5>
            <OrderSummary orderDetails={orderDetails} />
          </Grid>
        </>
      )}
      <Dialog
        open={openImage}
        onClose={() => {
          setOpenImage(false);
          setImageUrl(null);
        }}
        fullWidth
      >
        <DialogTitle>Upload Delivered Image</DialogTitle>
        <DialogContent>
          <div>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {imageUrl && (
              <div>
                <img src={imageUrl} alt="Selected" style={{ width: "100%" }} />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenImage(false);
              setImageUrl(null);
            }}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => deliverOrder()}>
            Deliver
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewDriverOrder;
