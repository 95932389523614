import { Alert, Button, Grid, Paper, Stack, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormInputText } from "../../../../components/form-components/FormInputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sectionOneSchema } from "../../../../app/schema";
import { FormInputDropdown } from "../../../../components/form-components/FormInputDropdown";
import Loader from "../../../../components/loader/Loader";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import axios from "axios";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useDispatch } from "react-redux";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../../../redux/counterSlice";

function SectionTwo({ regionId }) {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [activeBtn, setActiveBtn] = useState("ACTIVE");
  const [searchText, setSearchText] = useState("");

  const methods = useForm({
    resolver: yupResolver(sectionOneSchema),
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;
  const toggleStatus = (item) => {
    setLoader(true);
    const sectionId = 3;
    if (item?.app_image?.app_product_image) {
      axios
        .get(`/updateProductIds/${sectionId}/${item?.id}/${regionId}`)
        .then(function () {
          loadInitialData();
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
          dispatch(hideConfirmModal({ msg: "" }));
        });
    } else {
      toast.error("Please upload image");
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "app_product_image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = tableList?.[dataIndex]?.app_image?.app_product_image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "product_name",
      label: "Product Name",
    },
    {
      name: "Category",
      label: "Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const categoryName = tableList[dataIndex]?.category?.category_name;
          const subCategoryName =
            tableList[dataIndex]?.sub_category?.sub_category_name;

          return (
            <>
              <p className="m-0">{categoryName != null ? categoryName : "-"}</p>
              <small style={{ margin: 0, fontStyle: "italic" }}>
                {subCategoryName != null ? `( ${subCategoryName} )` : "-"}
              </small>
            </>
          );
        },
      },
    },
    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status =
            activeBtn === "ACTIVE" ? tableList?.[dataIndex]?.active_status : 0;
          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() =>
                dispatch(
                  showConfirmModal({
                    msg:
                      status === 1
                        ? "remove from Preview"
                        : "add this to Preview",
                    action: () => toggleStatus(tableList[dataIndex]),
                  })
                )
              }
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
  };

  const onSubmit = (data) => {
    saveHeading(data);
  };
  const saveHeading = async (data) => {
    const sectionId = 3;
    if (data?.heading) {
      axios
        .get(`/updateSectionHeading/${sectionId}/${data?.heading}/${regionId}`)
        .then(function (response) {
          if (!response?.data?.error) {
            toast.success(response?.data?.message);
            loadInitialData();
          } else {
            let errDesc = "";
            if (response?.data?.data) {
              Object.values(response?.data?.data).map((error, index) => {
                errDesc =
                  index === 0
                    ? errDesc + error + "\n "
                    : errDesc + error + "\n ";
              });
            } else {
              errDesc = response?.data?.message;
            }

            dispatch(
              showModal({
                msg: errDesc,
                error: response?.data?.error,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            showModal({
              msg: error?.message,
              error: true,
            })
          );
        });
    }
  };

  const loadInitialData = async () => {
    try {
      setLoader(true);
      const sectionId = 3;
      const [homeResponse, productResponse] = await axios.all([
        axios.get("/getAppHomeData/" + regionId),
        axios.get(`/product/inactive/${sectionId}/${regionId}`),
      ]);
      setActiveList(homeResponse?.data?.data?.sections?.[1]);
      setInactiveList(productResponse?.data?.data);
    } catch (error) {
      console.error("Error loading initial data:", error);
    } finally {
      setLoader(false);
      dispatch(hideConfirmModal({ msg: "" }));
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (!loader) {
      setTableList(
        activeBtn === "ACTIVE" ? activeList?.products : inactiveList
      );
    }
  }, [activeBtn, loader, activeList, inactiveList]);

  return loader ? (
    <Loader />
  ) : (
    <>
      <Paper
        elevation={3}
        sx={{
          margin: "15px auto",
          flexGrow: 1,
          p: 3,
          pt: 0,
          width: "100%",
        }}
      >
        <Grid
          container
          spacing={2}
          columnSpacing={{ xs: 5 }}
          style={{ display: "flex" }}
        >
          <Grid item xs={5}>
            <FormInputText
              name="heading"
              control={control}
              label="Heading"
              errors={errors}
              mandatory="true"
              defaultValue={activeList?.headings}
            />
          </Grid>

          <Grid item xs={3} margin={"auto 0px"}>
            <Button
              disabled={watch("heading")?.length === 5}
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <MUIDataTable
        data={tableList}
        columns={columns}
        options={options}
        title={
          <div>
            <Button
              className="mx-2"
              size="small"
              variant={activeBtn === "ACTIVE" ? "contained" : "outlined"}
              onClick={() => setActiveBtn("ACTIVE")}
              icon={<AddIcon />}
            >
              Active
            </Button>
            <Button
              className="mx-2"
              size="small"
              color="primary"
              variant={activeBtn === "ACTIVE" ? "outlined" : "contained"}
              onClick={() => setActiveBtn("INACTIVE")}
              icon={<AddIcon />}
            >
              In-Active
            </Button>
          </div>
        }
      />
    </>
  );
}

export default SectionTwo;
