import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CancelOutlined,
  PauseCircleOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
// const options = ["View", "Hold", "Cancel"];
const options = ["View"];

const icons = [VisibilityOutlined, PauseCircleOutlined, CancelOutlined];

export default function ThreeDotMenu({ id }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    handleClose();
    if (index === 0) {
      navigate("/order-list/" + id);
    }
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option, index) => {
          const Icon = icons[index];

          return (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Icon className="order-split-icon" sx={{ width: 24 }} />
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
