import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputText } from "../../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import { flavourSchema } from "../../../app/schema";
import { showModal, storeFlavourList } from "../../../redux/counterSlice";
import GeneralImageUpload from "../../../components/form-components/GeneralImageUpload";
import { grey, orange } from "@mui/material/colors";
import { PhotoProvider, PhotoView } from "react-photo-view";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
function Flavour() {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(flavourSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState("");

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/getAllFlavourList")
      .then(function (response) {
        setFormData(response?.data?.data);
        setLoader(false);
        reloadStore();
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };
  const reloadStore = () => {
    axios
      .get("/getAllActiveFlavourList")
      .then(function (response) {
        response?.data?.data?.length > 0 &&
          dispatch(storeFlavourList(response?.data?.data));
      })
      .catch(function (err) {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "flavour_id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = formData[dataIndex]?.image_url;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },
    {
      name: "flavour_name",
      label: "Flavour Name",
    },
    {
      name: "product_count",
      label: "Product Count",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const count = formData[dataIndex]?.product_count;
          return <Chip label={count} color="primary" />;
        },
      },
    },
    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          const imageUrl = formData[dataIndex]?.image_url;

          return (
            <Switch
              disabled={!imageUrl}
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => toggleStatus(formData[dataIndex]?.flavour_id)}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;

          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => {
                  setSelectedRow(() => ({ ...formData[dataIndex] }));
                  setOpenImage(true);
                }}
              >
                <AddPhotoAlternateIcon
                  sx={{ color: status === 1 ? grey[600] : orange[500] }}
                  fontSize="medium"
                />
              </Button>
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);
    setSelectedRow(null);
    reset();
  };
  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };
  const onSubmit = (data) => {
    saveForm(data);
  };

  const toggleStatus = (flavour_id) => {
    setLoader(true);
    axios
      .get("/flavourSwitch/" + flavour_id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const saveForm = async (data) => {
    setLoader(true);
    try {
      let response;
      if (selectedRow?.flavour_id) {
        response = await axios.post("/updateFlavour", {
          ...data,
          flavour_id: selectedRow?.flavour_id,
        });
      } else {
        response = await axios.post("/createFlavour", data);
      }

      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
        setOpen(false);
        setSelectedRow(() => null);
        reset();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }

        dispatch(
          showModal({
            msg: errDesc,
            error: response?.data?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          msg: error?.message,
          error: true,
        })
      );
    } finally {
      setLoader(false);
    }
  };

  const CreateEditDialog = React.memo(() => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle className="text-primary">
          {selectedRow ? "Edit Flavour" : "New Flavour"}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 5 }}>
            <Grid item>
              <FormInputText
                name="flavour_name"
                control={control}
                label="Flavour Name"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.flavour_name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  const UpdateImageDialog = () => {
    return (
      <Dialog open={openImage} onClose={handleClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Update Image</DialogTitle>
        <Divider variant="middle" className="bg-primary" />

        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <GeneralImageUpload
                status={selectedRow?.image}
                name={selectedRow?.flavour_id?.toString()}
                control={control}
                label={selectedRow?.flavour_name}
                setValue={setValue}
                type="text"
                defaultValue={selectedRow?.image_url}
                loadInitialData={loadInitialData}
                url="imageUpdateFlavour"
                formDataKey={{ id: "flavour_id", image: "flavour_image" }}
                dimension="Aspect Ratio 1:1"
                setOpenImage={setOpenImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    customSearch: false,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Flavour</h4>

          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                  reset();
                }}
                icon={<AddIcon />}
              >
                New Flavour
              </Button>
            }
          />
        </>
      )}
      <CreateEditDialog />
      <UpdateImageDialog />
    </>
  );
}

export default Flavour;
