import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import Loader from "../../../components/loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addonSchema } from "../../../app/schema";
import { hideConfirmModal, showModal } from "../../../redux/counterSlice";
import { parseArray } from "../../../app/utlils";
import DispatchOrderItem from "./DispatchOrderItem";

function DispatchPanel() {
  const dispatch = useDispatch();
  const { taxList, regionList } = useSelector((state) => state.counter);

  const methods = useForm({
    resolver: yupResolver(addonSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [location, setLocation] = useState(0);
  const [locationList, setLocationList] = useState();

  const loadInitialData = () => {
    const url = "/getAllActiveLocationDetails";
    setLoader(true);
    axios
      .get(url)
      .then(function (response) {
        setLocationList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };

  const listAllDispatchOrders = () => {
    if (location > 0) {
      const url = "/getDispatchOrderById/" + location;
      setLoader(true);
      axios
        .get(url)
        .then(function (response) {
          setOrderList(response?.data?.data);
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
          dispatch(hideConfirmModal({ msg: "" }));
        });
    }
  };

  const handleLocationChange = (value) => {
    setLocation(value);
    sessionStorage.setItem("dispatch_selected_location", value);
  };
  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    listAllDispatchOrders();
  }, [location]);
  useEffect(() => {
    const selectedLocation = sessionStorage.getItem(
      "dispatch_selected_location"
    );
    setLocation(selectedLocation);
  }, [orderList]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Dispatch Panel</h4>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              margin: "auto",
              marginTop: "15px",
              flexGrow: 1,
            }}
          >
            <Grid
              container
              columnSpacing={{ xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <FormControl
                  error={errors?.["order_status"]?.message ? true : false}
                  margin="normal"
                  className="w-100"
                >
                  <InputLabel id="status_dropdown_label">
                    Processing Team
                  </InputLabel>
                  <Select
                    labelId="status_dropdown_label"
                    id={`status_dropdown_select`}
                    value={location}
                    label="Update"
                    onChange={(e) => handleLocationChange(e?.target?.value)}
                  >
                    <MenuItem value={0}>--Select Location--</MenuItem>
                    {locationList?.map((item) => (
                      <MenuItem value={item?.location_details_id}>
                        {item?.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  spacing={3}
                >
                  {orderList?.length > 0 ? (
                    <>
                      {orderList.map((dispatch, index) => (
                        <Grid item xs={4} key={index}>
                          <DispatchOrderItem dispatch={dispatch} />
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Stack sx={{ width: "100%", mt: 3, p: 3 }} spacing={2}>
                      <Alert severity="warning">
                        <h6>No Data Found..</h6>
                      </Alert>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}

export default DispatchPanel;
