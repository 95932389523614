import React, { memo, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { showModal } from "../../app/store/slice/counterSlice";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { routesDropDown } from "../../app/utlils";
import { FormAutoComplete } from "./FormAutoComplete";
import { useDispatch } from "react-redux";
import { FormInputDropdown2 } from "./FormInputDropdown2";
import { FormInputDropdown } from "./FormInputDropdown";

const RouteDialog = React.memo(
  ({ open, onClose, control, errors, setValue, routeValue, routeList }) => {
    const routeType =
      useWatch({ control, name: "route_type" }) || routeValue?.route_type;
    const SELECT_OPTION = { value: "", label: "--SELECT PRODUCT--" };
    useEffect(() => {
      setValue("route_item", "");
    }, [routeType]);

    return (
      <Dialog fullWidth={true} open={open} onClose={onClose} maxWidth={"xs"}>
        <DialogTitle className="text-primary">Select Route</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={{ xs: 4 }}>
            <Grid item xs={12}>
              <Controller
                defaultValue={routeValue?.route_type}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl
                      fullWidth
                      error={errors?.["route_type"]?.message ? true : false}
                      margin="normal"
                    >
                      <InputLabel id="route_type_label"> Category</InputLabel>
                      <Select
                        labelId="route_type_label"
                        id={`route_type_elect`}
                        // value={value || productData?.category_id}
                        value={value || ""}
                        label="Category"
                        onChange={(e) => {
                          onChange(e);
                          setValue("route_item", "");
                        }}
                      >
                        <MenuItem value={""} key={0}>
                          --SELECT--
                        </MenuItem>
                        {routesDropDown?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
                control={control}
                name={"route_type"}
              />
            </Grid>
            <Grid item xs={12}>
              {routeType == "C" && (
                <FormInputDropdown
                  name="route_item"
                  control={control}
                  label="Category"
                  type="single"
                  options={routeList?.category?.map((item) => ({
                    id: item?.id,
                    name: item?.category_name,
                  }))}
                  errors={errors}
                  defaultValue={routeValue?.route_item || ""}
                  mandatory="true"
                />
              )}
              {routeType == "SC" && (
                <FormInputDropdown
                  name="route_item"
                  control={control}
                  label="Sub Category"
                  type="single"
                  options={routeList?.sub_category?.map((item) => ({
                    id: item?.id,
                    name: item?.sub_category_name,
                  }))}
                  errors={errors}
                  defaultValue={routeValue?.route_item || ""}
                  mandatory="true"
                />
              )}
              {routeType == "P" && (
                <FormAutoComplete
                  name="route_item"
                  control={control}
                  label="Product"
                  multiple={false}
                  dataArray={[
                    SELECT_OPTION,
                    ...routeList?.product?.map((item) => ({
                      value: item?.id,
                      label: item?.product_name,
                    })),
                  ]}
                  errors={errors}
                  defaultValue={{
                    value: routeValue?.route_item || SELECT_OPTION?.value,
                    label:
                      routeList?.product?.find(
                        (item) => item?.id == routeValue?.route_item
                      )?.product_name || SELECT_OPTION?.label,
                  }}
                  mandatory="true"
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
const ImageUpload = ({
  name,
  label,
  status,
  defaultValue,
  mandatory,
  loadInitialData,
  regionId,
  routeValue,
  routeList,
}) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const aRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [routeOpen, setRouteOpen] = useState(false);

  const handleRoute = () => {
    setRouteOpen(true);
  };
  useEffect(() => {
    if (defaultValue) {
      setImageUrl(defaultValue);
    }
  }, [defaultValue]);
  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      // setValue(name, file);
      setSelectedImage({ name: file });
      setImageUrl(URL.createObjectURL(file));
    }
  };
  const onSubmit = (data) => upload(data);

  const upload = (data) => {
    console.log(data);
    if (selectedImage?.name || data?.route_type) {
      setLoader(true);
      const formData = new FormData();
      selectedImage?.name &&
        formData.append("banner_image", selectedImage?.name);
      formData.append("banner_id", name);
      formData.append("region_id", regionId);
      formData.append("route_type", data?.route_type);
      if (data?.route_type == "P") {
        formData.append("route_item", data?.route_item?.value);
      } else {
        formData.append("route_item", data?.route_item);
      }

      axios
        .post("/imageUpdateBanner", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            setImageUrl(response?.data?.data);
            loadInitialData();
            dispatch(
              showModal({
                msg: response?.data?.message,
                error: response?.data?.error,
              })
            );
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            dispatch(
              showModal({
                msg: errDesc || response?.data?.message,
                error: response?.data?.error,
              })
            );
          }
          setLoader(false);
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          dispatch(
            showModal({
              msg: msg,
              error: true,
            })
          );
        })
        .finally(() => {
          setLoader(false);
          setOpen(false);
        });
    }
  };

  const toggleBannerStatusHandler = () => {
    setLoader(true);
    axios
      .get("/bannerSwitch/" + name)
      .then(function (response) {
        if (!response?.data?.error) {
          loadInitialData();
        } else {
          let errDesc = "";
          Object.values(response?.data?.data).map((error, index) => {
            errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
          });
          // toast.error(errDesc);
        }
      })
      .catch(function (error) {
        toast.error(error?.message);
      })
      .finally(() => {
        setLoader(false);
        setOpen(false);
      });
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          margin: "auto",
          marginTop: "15px",
          flexGrow: 1,
        }}
      >
        <div>
          <label
            htmlFor="formFile"
            className="form-label"
            style={{ textTransform: "capitalize" }}
          >
            {label}
            {mandatory && (
              <span style={{ color: "red", paddingLeft: "2px" }}>*</span>
            )}
          </label>
          <div className="row">
            <div className="col-10">
              <input
                ref={aRef}
                className="form-control"
                name={name}
                type="file"
                onChange={fileHandler}
                accept="image/png, image/jpeg, image/webp"
              />
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <AdsClickIcon
                color={routeValue?.route_item ? "primary" : "action"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleRoute(name)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 banner-card mt-4 p-0">
              {imageUrl ? (
                <PhotoProvider>
                  <PhotoView key={name} src={imageUrl}>
                    <img
                      src={imageUrl}
                      alt=""
                      className="w-100 h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </PhotoView>
                </PhotoProvider>
              ) : (
                <h1>2 :1 ratio</h1>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col m-2 gap-3 justify-content-center d-inline-flex">
              {imageUrl && !loader && (
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loader}
                >
                  Save
                </Button>
              )}

              {imageUrl && !loader && (
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => setOpen(true)}
                  disabled={loader}
                >
                  {status == 1 ? "Deactivate" : "Activate"}
                </Button>
              )}
              {loader && (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Paper>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {status == 1 ? "Deactivate" : "Activate"} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleBannerStatusHandler()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <RouteDialog
        open={routeOpen}
        onClose={() => setRouteOpen(false)}
        control={control}
        errors={errors}
        setValue={setValue}
        routeValue={routeValue}
        routeList={routeList}
      />
    </>
  );
};
export default memo(ImageUpload);
